<template>
  <b-jumbotron header-level="4" class="header">
    <template #header>
      {{ title }} <span class="d-none d-lg-inline-flex" v-if="subtitle">-</span> {{ subtitle }}
    </template>
  </b-jumbotron>
</template>

<script>
export default {
  props: ['title', 'subtitle'],
  name: 'HeaderPage'
}
</script>
<style scoped>
@media (min-width: 1100px) {
  .header {
    width: 100%;
    height: auto;

    display: flex;
    align-items: center;
    justify-content: center;

    background-image: url("../global/image/cpv-fundo-4.png");
    background-repeat: repeat;
    background-position: center;
    background-color: #F16F31;

    border-radius: 0;
  }

  .header h1 {
    font-size: 3.5rem;
    font-weight: 700;
    line-height: 66px;
    letter-spacing: 0%;
    text-transform: uppercase;
    color: #F6F6F6;
  }

}

@media only screen and (max-device-width: 1100px) {
  .header {
    width: 100%;
    height: auto;
    padding-left: 16px;
    padding-right: 16px;

    display: flex;
    align-items: center;
    justify-content: center;

    background-image: url("../global/image/cpv-fundo-4.png");
    background-repeat: repeat;
    background-position: center;
    background-color: #F16F31;

    border-radius: 0;
  }

  .header h1 {
    font-size: 2.8rem;
    font-weight: 700;
    line-height: 52px;
    letter-spacing: 0%;
    text-transform: uppercase;
    text-align: center;
    color: #F6F6F6;
  }

}

@media only screen and (max-width: 1100px) {
  .header {
    width: 100%;
    height: auto;
    padding-left: 16px;
    padding-right: 16px;

    display: flex;
    align-items: center;
    justify-content: center;

    background-image: url("../global/image/cpv-fundo-4.png");
    background-repeat: repeat;
    background-position: center;
    background-color: #F16F31;

    border-radius: 0;
  }

  .header h1 {
    font-size: 2.8rem;
    font-weight: 700;
    line-height: 52px;
    letter-spacing: 0%;
    text-transform: uppercase;
    text-align: center;
    color: #F6F6F6;
  }

}
</style>