<template>
  <div class="mainbase">
    <cpv-header class="menubase"></cpv-header>
    <div id="content" class="main-content">
      <router-view />
      <FaviconSwitcher />
    </div>
    <cpv-footer :footer-type="'grupo'" class="footerbase"></cpv-footer>
    <vue-snotify></vue-snotify>
    <WhatsappButton />
  </div>
</template>

<script>
import cpvHeader from "@/components/global/header.vue";
import cpvFooter from "@/components/global/newFooter";
import FaviconSwitcher from "@/components/global/faviconSwitcher.vue";

export default {
  metaInfo: {
    title: "Colégio CPV",
    titleTemplate: "%s - Colégio CPV",
  },
  components: {
    cpvHeader,
    cpvFooter,
    FaviconSwitcher,
  },
  computed: {},
  data() {
    return {};
  },
  mounted() {
    if (process.env.MIX_ENV_MODE === "production") {
      var script =
        "https://d335luupugsy2.cloudfront.net/js/loader-scripts/0bffb181-048f-4bc0-8cbd-93d500f91397-loader.js";
      let tag = document.createElement("script");
      tag.setAttribute("src", script);
      document.head.appendChild(tag);
    }
  },
};
</script>

<style scoped>
.mainbase {
  width: 100vw;
  height: auto;
  overflow-x: hidden;
  display: grid;
  grid-template-areas: 
  'menubase menubase menubase'
  'main-content main-content main-content'
  'footerbase footerbase footerbase';
}

.menubase {
  grid-area: menubase;
}

.main-content {
  grid-area: main-content;
}

.footerbase {
  grid-area: footerbase;
}


/* .main-content {
  font-family: "Clan-Book Book", "FF Clan Pro", sans-serif !important;
}
.viewFooter {
  display: block;
}
.hideFooter {
  display: none;
} */
</style>
