<template>
  <section class="animate__animated animate__fadeIn">
    <HeaderTitle :title="'Quem Somos'"/>
    <BreadCpv />

    <header class="pt-5">
      <h1 class="display-5 text-uppercase text-center font-weight-bolder">{{ ano }} anos de História</h1>
    </header>

    <article class="pt-4 pb-5">
      <b-container fluid="lg">
        <p>
          O CPV foi criado em <strong>1959</strong>, iniciando suas atividades como <strong>Preparatório para o Vestibular da FGV</strong>, diferenciado dos demais por exigir um programa específico.
        </p>
        <p>
          Posteriormente, foi abrindo o seu leque de opções. Atualmente, mantém turmas exclusivas para a FGV (Administração, Direito e Economia), para o Insper, USP,  Unesp, Unicamp, Unifesp, Medicina Einstein e outras.
        </p>
        <p>
          Em <strong>1993</strong>, foi concretizado um de nossos grandes projetos: <strong>foi aberto o Colégio CPV, Ensino Médio,</strong> baseado na larga experiência com o ensino de alto nível. Com metodologia própria e de acordo com a mais moderna proposta educacional, vem atingindo ao longo desses anos, excelentes resultados e altos índices de aprovação nas melhores universidades do país, além de proporcionar uma formação de qualidade.
        </p>
        <p>
          Motivado com tantas histórias de sucesso ao longo de todos esses anos, no ano de <strong>2016</strong>, o Colégio CPV se expandiu, <strong>abrindo as turmas do Ensino Fundamental Anos Finais,</strong> com uma proposta dinâmica e interativa e em consonância com uma sociedade pluralista e em constante transformação. 
        </p>
      </b-container>
    </article>
  </section>
</template>
<script>
import HeaderTitle from '@/components/global/headerTitle.vue'
import BreadCpv from '@/components/global/bread-cpv.vue'
export default {
  name: 'quemSomosColegio',
  components: { HeaderTitle, BreadCpv },
  computed: {
    ano() {
      let currentTime = new Date();
      let year = currentTime.getFullYear();
      return year - 1959;
    }
  }
}
</script>
<style scoped>
header h1.display-5 {
  /* font-size: 2.5rem; */
  color: #F16F31;
}


</style>