<template>
  <div v-if="slides.length" id="banner__site">
    <b-carousel
      v-model="initialSlide"
      :interval="interval"
      fade
      background="#ababab"
      no-hover-pause
      no-touch
    >
      <b-carousel-slide v-for="(slide, i) in slides" :key="i.id" class="carousel-slide-site">
        <template v-slot:img>
          <a v-if="slide.image_link" :href="slide.image_link" target="_blank">
            <b-img :src="slide.image_url" alt="slide.titulo" id="image__banner" />
          </a>
          <b-img v-else :src="slide.image_url" alt="slide.titulo" />
        </template>
        <template v-slot:default>
          <div v-if="slide.video">
            <video :src="slide.video" controls class="d-block w-100"></video>
          </div>
          <!-- <div class="carousel-caption d-none d-md-block">
            <h3>{{ slide.titulo }}</h3>
            <p>{{ slide.text }}</p>
          </div> -->
        </template>
      </b-carousel-slide>
    </b-carousel>
  </div>
</template>

<script>
import ApiService from "@/common/api.service";
export default {
  name: "CarouselSlider",
  props: {
    sliderType: {
      type: String,
      default: "colegio",
    },
  },
  data() {
    return {
      initialSlide: 0,
      slides: [],
      interval: 9000,
      progress: 0,
      progressInterval: undefined,
    };
  },
  created() {
    this.getSlides();
  },
  methods: {
    getSlides() {
      ApiService.get(`sitecpv/getBanners/${this.sliderType}`)
        .then((response) => {
          this.slides = response.data.banners;

          this.slides.forEach((element) => {
            let tag = document.createElement("link");
            tag.setAttribute("rel", "preload");
            tag.setAttribute("href", element.image_url);
            tag.setAttribute("as", "image");
            document.head.appendChild(tag);
          });
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
  },
};
</script>

<style scoped>
.carousel-slide-site #image__banner {
  width: 100%;
  height: auto;
}
</style>
