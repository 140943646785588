<template>
  <section class="menuHeader">
    <b-nav align="end" small class="d-none d-lg-flex">
      <b-nav-item href="https://cpv.com.br" link-classes="text-uppercase text-white font-weight-bolder border-right pt-1 pb-1">Grupo CPV</b-nav-item>
      <b-nav-item href="https://cursinho.cpv.com.br" link-classes="text-uppercase text-white font-weight-bolder border-right pt-1 pb-1">Cursinho CPV</b-nav-item>
      <b-nav-item href="https://colegio.cpv.com.br" link-classes="text-uppercase text-white font-weight-bolder border-right pt-1 pb-1">Colégio CPV</b-nav-item>
      <b-nav-item href="https://sistemacpv.com.br" link-classes="text-uppercase text-white font-weight-bolder border-right pt-1 pb-1">Sistema CPV</b-nav-item>
      <b-nav-item href="https://alfred.com.br" link-classes="text-uppercase text-white font-weight-bolder pt-1 pb-1">Alfred</b-nav-item>
    </b-nav>
    <b-navbar ref="mainNav" toggleable="lg" type="dark" :class="$route.name == 'homecpv' ? 'bg_registro_fade' : 'bg_registro'">
      <b-navbar-brand :to="{ name: 'home-colegio' }">
        <b-img fluid :src="logoSrc" alt="Grupo CPV" width="90px" height="51.53px"></b-img>
      </b-navbar-brand>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <hr class="border-white d-lg-none">
        <b-navbar-nav class="ml-auto" v-if="menu">
          <div v-for="(menuItem, index) in menu.menu_items" :key="index">
            <b-nav-item 
              :to="{ name: menuItem.route_name }" 
              v-if="menuItem.type == 'link' && menuItem.route_name"
              exact-active-class="active"
              link-classes="text-uppercase font-weight-bolder link-primary"
            >
              {{menuItem.name}}
            </b-nav-item>
            <b-nav-item 
              :href="menuItem.path_url"
              v-if="menuItem.type == 'link' && menuItem.path_url"
              link-classes="text-uppercase font-weight-bolder link-primary"
            >
              {{ menuItem.name }}
            </b-nav-item>
            <b-nav-item-dropdown
              :text="menuItem.name"
              v-if="menuItem.type == 'sub' && menuItem.sub_items"  
              toggle-class="text-uppercase font-weight-bolder link-primary" 
              right
            >
              <div v-for="(grandchildItem, index) in menuItem.sub_items" :key="index" class="submenu">
                <b-dd-item
                  :to="{ name: grandchildItem.route_name }"
                  v-if="grandchildItem.route_name"
                  link-class="subitem"
                  exact-active-class="active"
                  :class="{ active: isActiveMenuItem(grandchildItem.routeName)}"
                >
                  {{ grandchildItem.name }}
                </b-dd-item>
                <b-dd-item
                  :href="grandchildItem.path_url"
                  v-if="grandchildItem.path_url"
                  link-class="subitem"
                >
                  {{ grandchildItem.name }}
                </b-dd-item>
              </div>
            </b-nav-item-dropdown>
          </div>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </section>
</template>
<script>
import ApiService from '@/common/api.service';

export default {
  name: 'menuHeader',
  data() { return { menu: [] }; },
  created() { this.getMenu(); },
  // mounted() { this.onScrollEvent(); },
  computed: {
    section() {
      return this.$router.currentRoute.meta.section_name;
    },
    isSmallerDevice() {
      return window.innerWidth <= 992;
    },
    headerType() {
      switch (this.$route.meta.section_name) {
        case "grupo":
          return "header-grupo";
          break;
        case "cursinho":
          return "header-cursinho";
          break;
        case "colegio":
          return "header-colegio";
          break;
        default:
          return "header-grupo";
          break;
      }
    },
    logoSrc() {
      switch (this.$route.meta.section_name) {
        case "grupo":
          return "https://mysociety.s3.amazonaws.com/logoCPV/logo-grupo.png";
          break;
        case "cursinho":
          return "https://mysociety.s3.amazonaws.com/logoCPV/new-logo-cpv.png";
          break;
        case "colegio":
          return "https://mysociety.s3.amazonaws.com/logoCPV/logo-colegio.png";
          break;
        default:
          return "https://mysociety.s3.amazonaws.com/logoCPV/cpv_educacional_positivo_sem_grafismo.png";
          break;
      }
    },
  },
  methods: {
    getMenu: function () {
      ApiService.get(`sitecpv/getMenu/${this.headerType}`)
        .then((response) => {
          this.menu = response.data.menu;
        })
        .catch((errors) => {
          console.log(errors);
        })
        .finally(() => {});
    },
    mountedMenu() {
      if (this.section === "grupo") {
        console.log(this.section);
      }

      if (this.section === "cursinho") {
        console.log(this.section);
      }
    },
    onScrollEvent() {
      window.onscroll = () => {
        let headerSticky = this.$refs.mainNav;
        if (!headerSticky) return;

        if (window.pageYOffset >= 10) {
          headerSticky.$el.classList.add("scrollHeader")
        } else {
          headerSticky.$el.classList.remove("scrollHeader")
        }
      }
    },
    isActiveMenuItem(routeName) {
      return this.$route.name === routeName ? 'active' : '';
    },
    addToggleClass() {
      document.querySelector("body").classList.toggle("rtl-enable");
    },
    removeCollapseInClass() {
      document.getElementById("nav-collapse").classList.remove("show");
    },
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.onScrollEvent)
  },
}
</script>