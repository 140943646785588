<template>
  <section class="animate__animated animate__fadeIn">
    <article class="down-banner">
      <CarouselSlide :slider-type="'colegio'" />
      <!-- <slider :slider-type="'colegio'"></slider> -->
    </article>

    <article id="price">
      <div class="container up-cards">
        <cardsHome />
      </div>
    </article>

    <article class="agendamento">
      <b-container>
        <div class="text-center">
          <h2 class="display-5">Admissão de novos alunos</h2>
          <p class="lead">Agende sua visita e venha conhecer nossa escola!</p>
          <b-link class="btn btn-admissao" to="/admissao-de-novos-alunos"
            >Clique aqui para agendar a sua visita</b-link
          >
        </div>
      </b-container>
    </article>
  </section>
</template>

<script>
// import banner from '../components/global/banners.text'
// import slider from "../components/vueper/slider.vue";
import cardsHome from "./cards-home.vue";
// import AtividadesComplementares from "@/components/global/AtividadesComplementares.vue";
import CarouselSlide from "@/components/vueper/carousel-slide.vue";

export default {
  name: "Index",
  components: {
    // banner,
    cardsHome,
    // AtividadesComplementares,
    // slider,
    CarouselSlide,
  },
};
</script>

<style scoped>
.up-cards {
  margin-top: 0px;
  position: relative;
}

@media (min-width: 1100px) {
  article #price {
    margin-bottom: 00px;
  }

  article .container {
    width: 100%;
    padding: 15px 0px;
    display: block;
    margin: 0px auto;
  }

  article .up-cards {
    margin-top: 0px;
    position: relative;
  }

  article.agendamento {
    background-color: #F6F6F6;
    background-size: cover;
    padding: 30px 0;
  }

  article.agendamento h2.display-5 {
    color: #F16F31;
    font-size: 2.5rem;
    font-weight: 700;
    line-height: 52px;
    letter-spacing: 0;

    text-transform: uppercase;
  }

  article.agendamento p.lead {
    color: #F16F31;
    font-size: 1.25rem;
    font-weight: 300;
    line-height: 26px;
    letter-spacing: 0;
  }

  article.agendamento .btn-admissao {
    min-width: 64px;
    height: 36px;

    display: inline-block;

    margin: 0px 0;
    padding-left: 16px;
    padding-right: 16px;

    white-space: nowrap;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;

    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0;

    text-transform: uppercase;
    color: #F16F31;

    background-image: none;
    background: linear-gradient(
      90deg,
      #F16F31 0%,
      #F16F31 50%,
      transparent 50%,
      transparent 100%
    );
    background-size: 200% 100%;
    background-position: right;

    transition: background color 0.5s ease;

    border-top-left-radius: 16px;
    border-bottom-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 16px;

    border: 1px solid #F16F31;
  }

  article.agendamento .btn-admissao:hover {
    background-position: left;
    transition: all 0.5s ease;
    color: #FFF;
  }
}
@media (max-device-width: 1100px) {
  article .down-banner {
    overflow-x: hidden;
    overflow-y: hidden;
  }

  article .container {
    width: 100%;
    padding: 15px 0px;
    display: block;
    margin: 0px auto;
  }

  article #price {
    margin-top: 0px;
  }

  article.agendamento {
    background-color: #F6F6F6;
    background-size: cover;
    padding: 30px 0;
  }

  article.agendamento h2.display-5 {
    color: #F16F31;
    font-size: 2.5rem;
    font-weight: 700;
    line-height: 52px;
    letter-spacing: 0;

    text-transform: uppercase;
  }

  article.agendamento p.lead {
    color: #F16F31;
    font-size: 1.25rem;
    font-weight: 300;
    line-height: 26px;
    letter-spacing: 0;
  }

  article.agendamento .btn-admissao {
    min-width: 64px;
    height: 36px;

    display: inline-block;

    margin: 0px 0;
    padding-left: 16px;
    padding-right: 16px;

    white-space: nowrap;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;

    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0;

    text-transform: uppercase;
    color: #F16F31;

    background-image: none;
    background: linear-gradient(
      90deg,
      #F16F31 0%,
      #F16F31 50%,
      transparent 50%,
      transparent 100%
    );
    background-size: 200% 100%;
    background-position: right;

    transition: background color 0.5s ease;

    border-top-left-radius: 16px;
    border-bottom-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 16px;

    border: 1px solid #F16F31;
  }

  article.agendamento .btn-admissao:hover {
    background-position: left;
    transition: all 0.5s ease;
    color: #FFF;
  }
}

@media (max-width: 1100px) {
  article .down-banner {
    overflow-x: hidden;
    overflow-y: hidden;
  }

  article .container {
    width: 100%;
    padding: 0px 0px;
    display: block;
    margin: 0px auto;
  }

  article #price {
    margin-top: 0px;
  }

  article.agendamento {
    background-color: #F6F6F6;
    background-size: cover;
    padding: 30px 0;
  }

  article.agendamento h2.display-5 {
    color: #F16F31;
    font-size: 2.5rem;
    font-weight: 700;
    line-height: 52px;
    letter-spacing: 0;

    text-transform: uppercase;
  }

  article.agendamento p.lead {
    color: #F16F31;
    font-size: 1.25rem;
    font-weight: 300;
    line-height: 26px;
    letter-spacing: 0;
  }

  article.agendamento .btn-admissao {
    min-width: 64px;
    height: 36px;

    display: inline-block;

    margin: 0px 0;
    padding-left: 16px;
    padding-right: 16px;

    white-space: nowrap;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;

    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0;

    text-transform: uppercase;
    color: #F16F31;

    background-image: none;
    background: linear-gradient(
      90deg,
      #F16F31 0%,
      #F16F31 50%,
      transparent 50%,
      transparent 100%
    );
    background-size: 200% 100%;
    background-position: right;

    transition: background color 0.5s ease;

    border-top-left-radius: 16px;
    border-bottom-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 16px;

    border: 1px solid #F16F31;
  }

  article.agendamento .btn-admissao:hover {
    background-position: left;
    transition: all 0.5s ease;
    color: #FFF;
  }
}
</style>
