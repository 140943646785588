<template>
  <section class="animate__animated animate__fadeIn">
    <HeaderTitle :title="'Ensino Fundamental'" :subtitle="'Anos Finais'" />
    <bread-cpv></bread-cpv>

    <article class="article">
      <b-container fluid="lg">
        <p>
          Nesta etapa da educação básica, de 6º a 9º ano, marcada pela transição da infância à adolescência, dedicamos
          nossos esforços em promover o autoconhecimento e a autonomia do nosso aluno, estruturando as condições para um
          aprendizado intenso nos letramentos das línguas portuguesa, inglesa e espanhola, bem como nos letramentos
          matemático e digital, melhorando a capacidade do nosso aluno de se cominucar, de pensar, de criar e de
          colaborar, fortalecendo sua autoestima.
        </p>
        <p>
          Conjuntamente aos letramentos, as ciências humanas e as ciências da natureza, interdisciplinarmente e
          contextualmente, complementam o processo educativo, integradas às atividades artísticas e físicas, tão
          necessárias ao crescimento corporal e intelectual do nosso aluno.
        </p>

        <div class="text-center">
          <b-link class="btn btn-admissao" to="/admissao-de-novos-alunos">Clique aqui para agendar a sua visita</b-link>
        </div>
      </b-container>
    </article>

    <article class="hours">
      <b-container>
        <b-row>
          <b-col sm="12" md="6" lg="4" v-for="(hours, index) in showHours.data" :key="index">
            <div class="hours-box">
              <i class="icon" :class="[hours.icon, hours.color, hours.bg_hours]"></i>
              <div>
                <span :class="[hours.color, hours.teste]">
                  {{ hours.hour }}
                </span>
                <p :class="hours.txt_color">{{ hours.text }}</p>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </article>

    <article class="content-matriz">
      <b-row>
        <b-col cols="12">
          <b-container class="d-flex justify-content-center">
            <div class="box-matriz">
              <div class="header_box-matriz">
                <h2 class="display-5">Matriz</h2>
                <div class="line my-3">
                  <div class="triangle"></div>
                </div>
              </div>

              <div class="table-mobile">
                <p class="mb-3 info">Clique sobre o Ano Escolar desejado e saiba mais sobre a matriz curricular.</p>
                <b-button v-b-toggle.sexto-ano class="w-100 button-collapse">6º ANO</b-button>
                <b-collapse id="sexto-ano" class="mt-2 mb-0" accordion="my-accordion">
                  <div class="header">
                    <b-row>
                      <b-col cols="8" class="materias">Matéria</b-col>
                      <b-col cols="4" class="aulas"> Aulas </b-col>
                    </b-row>
                  </div>
                  <div class="body-table" v-for="(matriz, index) in showMatriz.data" :key="index">
                    <b-row :class="index % 2 === 0 ? 'bg-white' : 'bg-lightwhite'">
                      <b-col cols="8" class="materia">{{ matriz.name }}</b-col>
                      <b-col cols="4" class="aulas">{{ matriz.amount }}</b-col>
                    </b-row>
                  </div>
                  <div class="footer-table">
                    <b-row class="bg-lightwhite">
                      <b-col cols="8" class="materia">Total</b-col>
                      <b-col cols="4" class="aulas">41</b-col>
                    </b-row>
                  </div>
                </b-collapse>
                <b-button v-b-toggle.setimo-ano class="w-100 button-collapse mt-1">7º ANO</b-button>
                <b-collapse id="setimo-ano" class="mt-2 mb-0" accordion="my-accordion">
                  <div class="header">
                    <b-row>
                      <b-col cols="8" class="materias">Matéria</b-col>
                      <b-col cols="4" class="aulas"> Aulas </b-col>
                    </b-row>
                  </div>
                  <div class="body-table" v-for="(matriz, index) in showMatriz.data" :key="index">
                    <b-row :class="index % 2 === 0 ? 'bg-white' : 'bg-lightwhite'">
                      <b-col cols="8" class="materia">{{ matriz.name }}</b-col>
                      <b-col cols="4" class="aulas">{{ matriz.amount }}</b-col>
                    </b-row>
                  </div>
                  <div class="footer-table">
                    <b-row class="bg-lightwhite">
                      <b-col cols="8" class="materia">Total</b-col>
                      <b-col cols="4" class="aulas">41</b-col>
                    </b-row>
                  </div>
                </b-collapse>
                <b-button v-b-toggle.oitavo-ano class="w-100 button-collapse mt-1">8º ANO</b-button>
                <b-collapse id="oitavo-ano" class="mt-2 mb-0" accordion="my-accordion">
                  <div class="header">
                    <b-row>
                      <b-col cols="8" class="materias">Matéria</b-col>
                      <b-col cols="4" class="aulas"> Aulas </b-col>
                    </b-row>
                  </div>
                  <div class="body-table" v-for="(matriz, index) in showMatriz.data" :key="index">
                    <b-row :class="index % 2 === 0 ? 'bg-white' : 'bg-lightwhite'">
                      <b-col cols="8" class="materia">{{ matriz.name }}</b-col>
                      <b-col cols="4" class="aulas">{{ matriz.amount }}</b-col>
                    </b-row>
                  </div>
                  <div class="footer-table">
                    <b-row class="bg-lightwhite">
                      <b-col cols="8" class="materia">Total</b-col>
                      <b-col cols="4" class="aulas">41</b-col>
                    </b-row>
                  </div>
                </b-collapse>
                <b-button v-b-toggle.nono-ano class="w-100 button-collapse mt-1">9º ANO</b-button>
                <b-collapse id="nono-ano" class="mt-2 mb-0" accordion="my-accordion">
                  <div class="header">
                    <b-row>
                      <b-col cols="8" class="materias">Matéria</b-col>
                      <b-col cols="4" class="aulas">Aulas</b-col>
                    </b-row>
                  </div>
                  <div class="body-table" v-for="(matriz, index) in showMatriz.data" :key="index">
                    <b-row :class="index % 2 === 0 ? 'bg-white' : 'bg-lightwhite'">
                      <b-col cols="8" class="materia">{{ matriz.name }}</b-col>
                      <b-col cols="4" class="aulas">{{ matriz.amount }}</b-col>
                    </b-row>
                  </div>
                  <div class="footer-table">
                    <b-row class="bg-lightwhite">
                      <b-col cols="8" class="materia">Total</b-col>
                      <b-col cols="4" class="aulas">41</b-col>
                    </b-row>
                  </div>
                </b-collapse>
              </div>

              <div class="table-desktop">
                <table class="w-100">
                  <thead>
                    <tr bgcolor="#e8e8e8">
                      <th class="content">Componente Curricular</th>
                      <th class="serie first">6º Ano</th>
                      <th class="serie second">7º Ano</th>
                      <th class="serie third">8º Ano</th>
                      <th class="serie fourth">9º Ano</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr bgcolor="#f6f6f6">
                      <td class="content">Língua Portuguesa</td>
                      <td class="amount">5</td>
                      <td class="amount">5</td>
                      <td class="amount">5</td>
                      <td class="amount">5</td>
                    </tr>
                    <tr>
                      <td class="content">Produção de Texto</td>
                      <td class="amount">2</td>
                      <td class="amount">2</td>
                      <td class="amount">2</td>
                      <td class="amount">2</td>
                    </tr>
                    <tr bgcolor="#f6f6f6">
                      <td class="content">Interpretação de Texto</td>
                      <td class="amount">1</td>
                      <td class="amount">1</td>
                      <td class="amount">1</td>
                      <td class="amount">1</td>
                    </tr>
                    <tr>
                      <td class="content">Matemática</td>
                      <td class="amount">7</td>
                      <td class="amount">7</td>
                      <td class="amount">7</td>
                      <td class="amount">7</td>
                    </tr>
                    <tr bgcolor="#f6f6f6">
                      <td class="content">Ciências</td>
                      <td class="amount">3</td>
                      <td class="amount">3</td>
                      <td class="amount">3</td>
                      <td class="amount">3</td>
                    </tr>
                    <tr>
                      <td class="content">História</td>
                      <td class="amount">3</td>
                      <td class="amount">3</td>
                      <td class="amount">3</td>
                      <td class="amount">3</td>
                    </tr>
                    <tr bgcolor="#f6f6f6">
                      <td class="content">Geografia</td>
                      <td class="amount">3</td>
                      <td class="amount">3</td>
                      <td class="amount">3</td>
                      <td class="amount">3</td>
                    </tr>
                    <tr>
                      <td class="content">Arte</td>
                      <td class="amount">2</td>
                      <td class="amount">2</td>
                      <td class="amount">2</td>
                      <td class="amount">2</td>
                    </tr>
                    <tr bgcolor="#f6f6f6">
                      <td class="content">Educação Física</td>
                      <td class="amount">2</td>
                      <td class="amount">2</td>
                      <td class="amount">2</td>
                      <td class="amount">2</td>
                    </tr>
                    <tr>
                      <td class="content">Inglês</td>
                      <td class="amount">4</td>
                      <td class="amount">4</td>
                      <td class="amount">4</td>
                      <td class="amount">4</td>
                    </tr>
                    <tr bgcolor="#f6f6f6">
                      <td class="content">Espanhol</td>
                      <td class="amount">2</td>
                      <td class="amount">2</td>
                      <td class="amount">2</td>
                      <td class="amount">2</td>
                    </tr>
                    <tr>
                      <td class="content">Atualidades</td>
                      <td class="amount">2</td>
                      <td class="amount">2</td>
                      <td class="amount">2</td>
                      <td class="amount">2</td>
                    </tr>
                    <tr bgcolor="#f6f6f6">
                      <td class="content">Comuputação</td>
                      <td class="amount">1</td>
                      <td class="amount">1</td>
                      <td class="amount">1</td>
                      <td class="amount">1</td>
                    </tr>
                    <tr>
                      <td class="content">Oratória</td>
                      <td class="amount">1</td>
                      <td class="amount">1</td>
                      <td class="amount">1</td>
                      <td class="amount">1</td>
                    </tr>
                    <tr bgcolor="#f6f6f6">
                      <td class="content">Educação Socioemocional</td>
                      <td class="amount">2</td>
                      <td class="amount">2</td>
                      <td class="amount">2</td>
                      <td class="amount">2</td>
                    </tr>
                    <tr>
                      <td class="content no-border">Empreendedorismo</td>
                      <td class="amount no-border">1</td>
                      <td class="amount no-border">1</td>
                      <td class="amount no-border">1</td>
                      <td class="amount no-border">1</td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr bgcolor="#e8e8e8">
                      <th class="serie first">Total</th>
                      <td colspan="4" class="content">41 Aulas Semanais</td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </b-container>
        </b-col>
      </b-row>
    </article>

    <article class="agendamento">
      <b-container>
        <div class="text-center">
          <h2 class="display-5">Admissão de novos alunos</h2>
          <p class="lead">Agende sua visita e venha conhecer nossa escola!</p>
          <b-link class="btn btn-admissao" to="/admissao-de-novos-alunos">Clique aqui para agendar a sua visita</b-link>
        </div>
      </b-container>
    </article>

    <article class="destaques">
      <div class="container-destaque">
        <header class="destaque-header">
          <h2 class="display-5">Destaques CPV</h2>
        </header>
        <div class="line my-3 mb-5">
          <div class="triangle"></div>
        </div>
        <b-row>
          <b-col class="mb-4" cols="12" sm="12" md="6" lg="4" xl="4" v-for="(destaques, index) in showDestaques.data"
            :key="index">
            <div class="destaque-box" v-bind:class="destaques.color">
              <i class="icon" :class="[destaques.icon, destaques.box_icon]"></i>
              <h3>{{ destaques.name }}</h3>
              <p>{{ destaques.info }}</p>
            </div>
          </b-col>
        </b-row>
      </div>
    </article>
  </section>
</template>

<script>
import HeaderTitle from "@/components/global/headerTitle.vue";
import BreadCpv from "@/components/global/bread-cpv.vue";

export default {
  name: "Fundamental",
  metaInfo: { title: "Ensino Fundamental - Anos Finais" },
  components: { HeaderTitle, BreadCpv },
  data: () => {
    return {
      showMatriz: {
        data: [
          {
            name: "Língua Portuguesa",
            amount: "5",
            schoolYear: [{ name: "6º Ano" }, { name: "7º Ano" }, { name: "8º Ano" }, { name: "9º Ano" }],
          },
          {
            name: "Produção de Texto",
            amount: "2",
            schoolYear: [{ name: "6º Ano" }, { name: "7º Ano" }, { name: "8º Ano" }, { name: "9º Ano" }],
          },
          {
            name: "Interpretação de Texto",
            amount: "1",
            schoolYear: [{ name: "6º Ano" }, { name: "7º Ano" }, { name: "8º Ano" }, { name: "9º Ano" }],
          },
          {
            name: "Matemática",
            amount: "7",
            schoolYear: [{ name: "6º Ano" }, { name: "7º Ano" }, { name: "8º Ano" }, { name: "9º Ano" }],
          },
          {
            name: "Ciências",
            amount: "3",
            schoolYear: [{ name: "6º Ano" }, { name: "7º Ano" }, { name: "8º Ano" }, { name: "9º Ano" }],
          },
          {
            name: "História",
            amount: "3",
            schoolYear: [{ name: "6º Ano" }, { name: "7º Ano" }, { name: "8º Ano" }, { name: "9º Ano" }],
          },
          {
            name: "Geografia",
            amount: "3",
            schoolYear: [{ name: "6º Ano" }, { name: "7º Ano" }, { name: "8º Ano" }, { name: "9º Ano" }],
          },
          {
            name: "Arte",
            amount: "2",
            schoolYear: [{ name: "6º Ano" }, { name: "7º Ano" }, { name: "8º Ano" }, { name: "9º Ano" }],
          },
          {
            name: "Educação Física",
            amount: "2",
            schoolYear: [{ name: "6º Ano" }, { name: "7º Ano" }, { name: "8º Ano" }, { name: "9º Ano" }],
          },
          {
            name: "Inglês",
            amount: "4",
            schoolYear: [{ name: "6º Ano" }, { name: "7º Ano" }, { name: "8º Ano" }, { name: "9º Ano" }],
          },
          {
            name: "Espanhol",
            amount: "2",
            schoolYear: [{ name: "6º Ano" }, { name: "7º Ano" }, { name: "8º Ano" }, { name: "9º Ano" }],
          },
          {
            name: "Atualidades",
            amount: "2",
            schoolYear: [{ name: "6º Ano" }, { name: "7º Ano" }, { name: "8º Ano" }, { name: "9º Ano" }],
          },
          {
            name: "Computação",
            amount: "1",
            schoolYear: [{ name: "6º Ano" }, { name: "7º Ano" }, { name: "8º Ano" }, { name: "9º Ano" }],
          },
          {
            name: "Oratória",
            amount: "1",
            schoolYear: [{ name: "6º Ano" }, { name: "7º Ano" }, { name: "8º Ano" }, { name: "9º Ano" }],
          },
          {
            name: "Educação Socioemocional",
            amount: "2",
            schoolYear: [{ name: "6º Ano" }, { name: "7º Ano" }, { name: "8º Ano" }, { name: "9º Ano" }],
          },
          {
            name: "Empreendedorismo",
            amount: "1",
            schoolYear: [{ name: "6º Ano" }, { name: "7º Ano" }, { name: "8º Ano" }, { name: "9º Ano" }],
          },
        ],
      },
      showDestaques: {
        data: [

          {
            name: "Educação Socioemocional",
            info: "Desenvolve habilidades essenciais para compreender e gerenciar emoções, fortalecer relações interpessoais e lidar com desafios de forma positiva. Competências fundamentais para o crescimento pessoal, o sucesso acadêmico e para a vida profissional.",
            icon: "fa-duotone fa-people-group",
            color: "orange",
            box_icon: "box-orange",
          },
          {
            name: "Atualidade em Debate",
            info: "Atualidades é uma disciplina dinâmica que conecta os alunos ao Brasil e ao mundo, estimulando o pensamento crítico e debates enriquecedores. Com a orientação do professor, os alunos pesquisam e discutem temas atuais, desenvolvendo habilidades como comunicação e argumentação.",
            icon: "fa-duotone fa-newspaper",
            color: "roxo-b",
            box_icon: "box-roxo-b",
          },
          {
            name: "Certificação Cambridge",
            info: "No Ensino Fundamental, os alunos têm 4 aulas semanais de inglês, organizadas em turmas conforme o nível de proficiência, do A2 ao C1. Essa abordagem personalizada oferece uma formação sólida no idioma e prepara os estudantes para conquistar os renomados Certificados Cambridge (UK).",
            icon: "fa-duotone fa-file-certificate",
            color: "coral",
            box_icon: "box-coral",
          },
          {
            name: "Computação",
            info: "A disciplina de Computação torna o aprendizado interativo, com foco em programação de jogos e aplicativos. Os alunos desenvolvem habilidades digitais essenciais e aprendem a usar a web de forma ética e responsável, preparando-se para os desafios do mundo digital.",
            icon: "fa-duotone fa-regular fa-computer",
            color: "roxo-b",
            box_icon: "box-roxo-b",
          },
          {
            name: "Empreendedorismo",
            info: "Empreender é aprender a criar, resolver problemas e trabalhar em equipe. Os alunos desenvolvem habilidades do século XXI, aplicando metodologias ágeis em projetos reais, equilibrando metas financeiras com responsabilidade social e ambiental, e se preparando para os desafios do futuro.",
            icon: "fa-duotone fa-briefcase",
            color: "coral",
            box_icon: "box-coral",
          },
          {
            name: "Oratória",
            info: "A Oratória transforma a comunicação em uma poderosa ferramenta para o sucesso. Ao desenvolver habilidades de expressão e autoconfiança, os alunos se preparam para se destacar nas relações sociais e conquistar oportunidades em diversas áreas.",
            icon: "fa-duotone fa-regular fa-presentation-screen",
            color: "orange",
            box_icon: "box-orange",
          },
          {
            name: "Apoio Pedagógico",
            info: "No apoio pedagógico as dúvidas são esclarecidas e os conteúdos reforçados com o objetivo de suprir possíveis defasagens de aprendizado e permitir o acompanhamento do conteúdo das aulas com qualidade.",
            icon: "fa-duotone fa-handshake",
            color: "coral",
            box_icon: "box-coral",
          },
        ],
      },
      showHours: {
        data: [
          {
            hour: "41",
            text: "Aulas semanais",
            icon: "fa-duotone fa-calendars",
            color: "orange",
            bg_hours: "bg-orange",
            txt_color: "text-color",
          },
          {
            hour: "8h às 16h30",
            text: "Período integral",
            icon: "fa-duotone fa-clock-eight",
            color: "roxo-b",
            bg_hours: "bg-roxo-b",
            txt_color: "text-color",
          },
          {
            hour: "16h30 às 18h",
            text: "Atividades Extras",
            icon: "fa-duotone fa-solid fa-futbol",
            color: "coral",
            bg_hours: "bg-coral",
            txt_color: "text-color",
          },
        ],
      },
    };
  },
};
</script>

<style scoped>
article {
  padding: 30px 0;
}

article p {
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0;
}

article .btn-admissao {
  min-width: 64px;
  height: 36px;

  display: inline-block;

  margin: 30px 0 0;
  padding-left: 16px;
  padding-right: 16px;

  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0;

  text-transform: uppercase;
  color: #FFF;

  background-image: none;
  background: linear-gradient(90deg, #F55911 0%, #F55911 50%, #F16F31 50%, #F16F31 100%);
  background-size: 200% 100%;
  background-position: right;

  transition: background 0.5s ease;

  border-top-left-radius: 16px;
  border-bottom-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 16px;
}

article .btn-admissao:hover {
  background-position: left;
  transition: all 0.5s ease;
}

@media (min-width: 992px) {
  article div.line {
    width: 10%;
    height: 2px;

    display: block;
    margin: 0 auto;
    position: relative;

    border: 2px solid #F16F31;
    border-radius: 16px;
  }

  article div.line::after,
  article div.line::before {
    content: '';
    position: absolute;
    top: -6px;
    left: 50%;
    transform: translateX(-50%);
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid #ED692C;

    border-radius: 4px;
  }

  article.hours {
    padding: 30px 0;
  }

  article.hours .hours-box {
    display: flex;
    align-items: center;
    padding: 30px 15px;
    background: #FFF;
    border-top-left-radius: 32px;
    border-bottom-right-radius: 32px;
    border-bottom-left-radius: 5px;
    border-top-right-radius: 5px;
    box-shadow: 0px 0px 8px rgba(1, 41, 112, 0.18);
    border: 1px solid rgba(1, 41, 112, 0.15);
  }

  article.hours .hours-box i {
    margin-right: 15px;
    font-size: 42px;
    line-height: 54px;
    letter-spacing: 0;
    padding: 10px 20px;
    border-radius: 4px;
  }

  article.hours .hours-box .text-color {
    color: #888;
  }

  article.hours .hours-box .bg-orange {
    background-color: rgba(241, 111, 49, 0.18);
  }

  article.hours .hours-box .orange {
    color: rgba(241, 111, 49, 1);
  }

  article.hours .hours-box .bg-roxo-b {
    background-color: rgba(98, 63, 153, 0.18);
  }

  article.hours .hours-box .roxo-b {
    color: rgba(98, 63, 153, 1)
  }

  article.hours .hours-box .bg-coral {
    background-color: rgba(240, 75, 93, 0.18);
  }

  article.hours .hours-box .coral {
    color: rgba(240, 75, 93, 1);
  }

  article.hours .hours-box div span {
    font-size: 34px;
    font-weight: 700;
    line-height: 42px;
    letter-spacing: 0;
  }

  article.hours .hours-box div p {
    margin: 0;
    padding: 0;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0;
    text-transform: uppercase;
  }

  article.content-matriz {
    height: 100%;
    background-color: transparent;
    padding: 0 0 50px;
  }

  article.content-matriz .box-matriz {
    width: 100%;
  }

  article.content-matriz .box-matriz>.header_box-matriz {
    padding: 20px 0;
    text-transform: uppercase;
    text-align: center;
  }

  article.content-matriz .box-matriz .header_box-matriz>h2.display-5 {
    font-size: 2.5rem;
    font-weight: 700;
  }

  article.content-matriz .table-mobile {
    display: none;
  }

  article.content-matriz .table-desktop>table {
    width: 100%;
    border: 0;
  }

  article.content-matriz .table-desktop>table thead tr th {
    color: #F6F6F6;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0;
  }

  article.content-matriz .table-desktop>table thead tr th.content {
    height: 36px;
    padding-left: 16px;
    padding-right: 16px;
    background-color: #F16F31;
    border-top-left-radius: 16px;
    border-bottom-right-radius: 16px;
    text-transform: uppercase;
  }

  article.content-matriz .table-desktop>table thead tr th.serie {
    text-align: center;
    color: #F16F31;
    text-transform: uppercase;
  }

  article.content-matriz .table-desktop>table thead tr th.first,
  article.content-matriz .table-desktop>table thead tr th.third {
    border-bottom-left-radius: 16px;
    border-top-right-radius: 16px;
  }

  article.content-matriz .table-desktop>table thead tr th.second,
  article.content-matriz .table-desktop>table thead tr th.fourth {
    border-bottom-right-radius: 16px;
    border-top-left-radius: 16px;
  }

  article.content-matriz .table-desktop>table tbody tr td {
    height: 36px;

    padding-left: 16px;
    padding-right: 16px;

    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0;

    color: #767676;

    border-bottom: 1px solid #e8e8e8;
  }

  article.content-matriz .table-desktop>table tbody tr td.content {
    padding-left: 16px;
    padding-right: 16px;
    font-weight: 500;
  }

  article.content-matriz .table-desktop>table tbody tr td.amount {
    text-align: center;
    border-right: 1px solid #E8E8E8;
    border-left: 1px solid #E8E8E8;
    font-weight: 600;
  }

  article.content-matriz .table-desktop>table tbody tr td.no-border {
    border-bottom: 0;
  }

  article.content-matriz .table-desktop>table tfoot tr {
    margin: 30px 0 0;
  }

  article.content-matriz .table-desktop>table tfoot tr td,
  article.content-matriz .table-desktop>table tfoot tr th {
    height: 36px;
    padding-left: 16px;
    padding-right: 16px;

    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0;
  }

  article.content-matriz .table-desktop>table tfoot tr th.serie {
    color: #F16F31;
    text-transform: uppercase;

    border-bottom-left-radius: 16px;
    border-top-right-radius: 16px;
  }

  article.content-matriz .table-desktop>table tfoot tr td.content {
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0;

    text-align: center;
    text-transform: uppercase;

    color: #F6F6F6;
    background-color: #F16F31;

    border-top-left-radius: 16px;
    border-bottom-right-radius: 16px;
  }

  article.agendamento {
    background-color: #F16F31;
    background-size: cover;
    padding: 30px 0;
  }

  article.agendamento h2.display-5 {
    color: #FFF;
    font-size: 2.5rem;
    font-weight: 700;
    line-height: 52px;
    letter-spacing: 0;

    text-transform: uppercase;
  }

  article.agendamento p.lead {
    color: #FFF;
    font-size: 1.25rem;
    font-weight: 300;
    line-height: 26px;
    letter-spacing: 0;
  }

  article.agendamento .btn-admissao {
    min-width: 64px;
    height: 36px;

    display: inline-block;

    margin: 0px 0;
    padding-left: 16px;
    padding-right: 16px;

    white-space: nowrap;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;

    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0;

    text-transform: uppercase;
    color: #FFF;

    background-image: none;
    background: linear-gradient(90deg, #FFF 0%, #FFF 50%, transparent 50%, transparent 100%);
    background-size: 200% 100%;
    background-position: right;

    transition: background color 0.5s ease;

    border-top-left-radius: 16px;
    border-bottom-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 16px;

    border: 1px solid #FFF;
  }

  article.agendamento .btn-admissao:hover {
    background-position: left;
    transition: all 0.5s ease;
    color: #F16F31;
  }

  article.destaques {
    padding: 30px 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }

  article.destaques .container-destaque {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    justify-content: center;
  }

  article.destaques .container-destaque>.destaque-header>h2.display-5 {
    font-size: 2.5rem;
    font-weight: 700;
    line-height: 52px;
    letter-spacing: 0;

    text-transform: uppercase;
  }

  article.destaques .container-destaque .destaque-box {
    box-shadow: 0 0 15px rgba(1, 41, 112, 0.18);
    height: 100%;
    padding: 60px 15px;
    text-align: center;
    transition: 0.3s;
    border-top-left-radius: 32px;
    border-bottom-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 32px;
  }

  article.destaques .container-destaque .destaque-box>i.icon {
    width: 80px;
    height: 80px;
    margin-bottom: 25px;

    display: flex;
    align-items: center;
    justify-content: center;

    margin: 0 auto 25px;
    position: relative;

    font-size: 36px;
    line-height: 44px;

    transition: 0.3s;
    border-radius: 5px;
  }

  article.destaques .container-destaque .destaque-box>i.box-orange {
    background-color: rgba(241, 111, 49, 0.18);
    color: #f16f31;
  }

  article.destaques .container-destaque .destaque-box>i.box-roxo-b {
    background-color: rgba(98, 64, 153, 0.18);
    color: #624099;
  }

  article.destaques .container-destaque .destaque-box>i.box-coral {
    background-color: rgba(240, 75, 93, 0.18);
    color: #f04b5d;
  }

  article.destaques .container-destaque .destaque-box>h3 {
    margin: 0 0 8px;
    color: #333;
    font-size: 28px;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: 0;
  }

  article.destaques .container-destaque .destaque-box>p {
    margin: 0 0 16px;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0;
  }

  article.destaques .orange {
    border-bottom: 3px solid #F16F31;
  }

  article.destaques .orange:hover {
    background: #F16F31;
    transition: all .3s ease;
  }

  article.destaques .orange:hover i.box-orange {
    background: #FFF;
    transition: all .3s ease;
  }

  article.destaques .orange:hover h3,
  article.destaques .orange:hover p {
    color: #FFF;
    transition: all .3s ease;
  }

  article.destaques .roxo-b {
    border-bottom: 3px solid #623F99;
  }

  article.destaques .roxo-b:hover {
    background: #623F99;
    transition: all .3s ease;
  }

  article.destaques .roxo-b:hover i.box-roxo-b {
    background: #FFF;
    transition: all .3s ease;
  }

  article.destaques .roxo-b:hover h3,
  article.destaques .roxo-b:hover p {
    color: #FFF;
    transition: all .3s ease;
  }

  article.destaques .coral {
    border-bottom: 3px solid #F04B5D;
  }

  article.destaques .coral:hover {
    background: #F04B5D;
    transition: all .3s ease;
  }

  article.destaques .coral:hover i.box-coral {
    background: #FFF;
    transition: all .3s ease;
  }

  article.destaques .coral:hover h3,
  article.destaques .coral:hover p {
    color: #FFF;
    transition: all .3s ease;
  }
}

@media only screen and (max-device-width: 992px) {
  article div.line {
    width: 35%;
    height: 2px;

    display: block;
    margin: 0 auto;
    position: relative;

    border: 2px solid #F16F31;
    border-radius: 16px;
  }

  article div.line::after,
  article div.line::before {
    content: '';
    position: absolute;
    top: -6px;
    left: 50%;
    transform: translateX(-50%);
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid #ED692C;

    border-radius: 4px;
  }

  article.hours .hours-box {
    display: flex;
    align-items: center;
    margin: 10px 0;
    padding: 30px 15px;
    background: #FFF;
    border-top-left-radius: 32px;
    border-bottom-right-radius: 32px;
    border-bottom-left-radius: 5px;
    border-top-right-radius: 5px;
    box-shadow: 0px 0px 8px rgba(1, 41, 112, 0.1);
    border: 1px solid rgba(1, 41, 112, 0.15);
  }

  article.hours .hours-box i {
    margin-right: 15px;
    font-size: 32px;
    line-height: 54px;
    letter-spacing: 0;
    padding: 10px 20px;
    border-radius: 4px;
  }

  article.hours .hours-box .text-color {
    color: #888;
  }

  article.hours .hours-box .bg-orange {
    background-color: rgba(241, 111, 49, 0.18);
  }

  article.hours .hours-box .orange {
    color: rgba(241, 111, 49, 1);
  }

  article.hours .hours-box .bg-roxo-b {
    background-color: rgba(98, 63, 153, 0.18);
  }

  article.hours .hours-box .roxo-b {
    color: rgba(98, 63, 153, 1)
  }

  article.hours .hours-box .bg-coral {
    background-color: rgba(240, 75, 93, 0.18);
  }

  article.hours .hours-box .coral {
    color: rgba(240, 75, 93, 1);
  }

  article.hours .hours-box div span {
    font-size: 30px;
    font-weight: 700;
    line-height: 42px;
    letter-spacing: 0;
  }

  article.hours .hours-box div p {
    margin: 0;
    padding: 0;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0;
    text-transform: uppercase;
  }

  article.content-matriz {
    height: 100%;
    background-color: transparent;
    padding: 0 0 50px;
  }

  article.content-matriz .box-matriz {
    width: 100%;
  }

  article.content-matriz .box-matriz>.header_box-matriz {
    padding: 20px 0;
    text-transform: uppercase;
    text-align: center;
  }

  article.content-matriz .box-matriz .header_box-matriz>h2.display-5 {
    font-size: 2.5rem;
    font-weight: 700;
  }

  article.content-matriz .table-desktop {
    display: none;
  }

  article.content-matriz .table-mobile {
    display: block;
  }

  article.content-matriz .table-mobile .info {
    font-size: 0.8em;
    letter-spacing: 0.008em;
    font-weight: 600;
  }

  article.content-matriz .table-mobile .button-collapse {
    border-radius: 5px;
    background-color: #F16F31;
    border: 1px solid #F16F31;
    font-weight: 700;
  }

  article.content-matriz .table-mobile .button-collapse:focus,
  article.content-matriz .table-mobile .button-collapse:active {
    box-shadow: 0rem 0.005rem 0.4rem #ED550F;
    border: 1px solid #F16F31;
  }

  article.content-matriz .table-mobile div.header,
  article.content-matriz .table-mobile div.body-table,
  article.content-matriz .table-mobile div.footer-table {
    padding: 10px 15px;
  }

  article.content-matriz .table-mobile div.header div.materias {
    padding: 5px 10px;
    
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0;

    color: #FFF;
    
    background-color: #F16F31;
    
    border: 1px solid #F16F31;
    border-bottom-right-radius: 10px;
    border-top-left-radius: 10px;
  }

  article.content-matriz .table-mobile div.header div.aulas {
    text-align: center !important;
    font-weight: 700;
    color: #F16F31;
    background-color: #E8E8E8;
    border-bottom-left-radius: 10px;
    border-top-right-radius: 10px;
    padding: 5px 10px;
  }

  article.content-matriz .table-mobile div.body-table {
    border-left: 1px solid #E0E0E0;
    border-right: 1px solid #E0E0E0;
    padding: 0px 15px;

    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0;
    color: #555555;
    text-transform: uppercase;
  }

  article.content-matriz .table-mobile div.body-table div.bg-lightwhite {
    background-color: #F6F6F6;
    padding: 10px 5px;
    border-bottom: 1px solid #E0E0E0;
  }

  article.content-matriz .table-mobile div.body-table div.bg-white {
    padding: 10px 5px;
    /* border-top: 1px solid #E0E0E0; */
    border-bottom: 1px solid #E0E0E0;
  }

  article.content-matriz .table-mobile div.body-table div.materia {
    font-weight: 500;
    display: flex;
    align-items: center;
  }

  article.content-matriz .table-mobile div.body-table div.aulas {
    text-align: center;
    font-weight: 600;
    color: #F16F31;
  }

  article.content-matriz .table-mobile div.footer-table div.materia {
    font-weight: 700;
    color: #F16F31;
    background-color: #E8E8E8;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    padding: 5px 10px;
  }

  article.content-matriz .table-mobile div.footer-table div.aulas {
    font-weight: 700;
    color: #FFF;
    border-top-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border: 1px solid #F16F31;
    background-color: #F16F31;
    padding: 5px 10px;
    text-align: center;
  }

  article.agendamento {
    background-color: #F16F31;
    background-size: cover;
    padding: 30px 0;
  }

  article.agendamento h2.display-5 {
    color: #FFF;
    font-size: 2.5rem;
    font-weight: 700;
    line-height: 52px;
    letter-spacing: 0;

    text-transform: uppercase;
  }

  article.agendamento p.lead {
    color: #FFF;
    font-size: 1.25rem;
    font-weight: 300;
    line-height: 26px;
    letter-spacing: 0;
  }

  article.agendamento .btn-admissao {
    min-width: 64px;
    height: 36px;

    display: inline-block;

    margin: 0px 0;
    padding-left: 16px;
    padding-right: 16px;

    white-space: nowrap;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;

    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0;

    text-transform: uppercase;
    color: #FFF;

    background-image: none;
    background: linear-gradient(90deg, #FFF 0%, #FFF 50%, transparent 50%, transparent 100%);
    background-size: 200% 100%;
    background-position: right;

    transition: background color 0.5s ease;

    border-top-left-radius: 16px;
    border-bottom-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 16px;

    border: 1px solid #FFF;
  }

  article.agendamento .btn-admissao:hover {
    background-position: left;
    transition: all 0.5s ease;
    color: #F16F31;
  }

  article.destaques {
    padding: 60px 16px;
  }

  article.destaques .container-destaque {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-left: 0;
  }

  article.destaques .container-destaque .destaque-header h2.display-5 {
    font-size: 2.5rem;
    font-weight: 700;
    line-height: 52px;
    letter-spacing: 0;
    text-transform: uppercase;
    text-align: center;
  }

  article.destaques .container-destaque .destaque-box {
    height: 100%;
    padding: 60px 30px;
    text-align: center;
    transition: all .3s ease;

    border-top-left-radius: 32px;
    border-bottom-right-radius: 32px;
    border-bottom-left-radius: 5px;
    border-top-right-radius: 5px;

    border: 1px solid rgba(1, 41, 112, 0.15);
    box-shadow: 0px 0px 8px rgba(1, 41, 112, 0.1);
  }

  article.destaques .container-destaque .destaque-box i.icon {
    font-size: 36px;
    padding: 40px 20px;
    border-radius: 4px;
    position: relative;
    margin-bottom: 25px;
    display: inline-block;
    line-height: 0;
    transition: 0.3s;
  }

  article.destaques .container-destaque .destaque-box h3 {
    margin: 0 0 8px;
    font-size: 1.75rem;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: 0;
    color: #333;
  }

  article.destaques .container-destaque .destaque-box p {
    margin: 0 0 1rem;
  }

  article.destaques .container-destaque .destaque-box .box-orange {
    background-color: rgba(241, 111, 49, 0.18);
    color: #f16f31;
  }

  article.destaques .container-destaque .destaque-box .box-roxo-b {
    background-color: rgba(59, 25, 83, 0.18);
  }

  article.destaques .container-destaque .destaque-box .box-coral {
    background-color: rgba(240, 75, 93, 0.18);
    color: #f04b5d;
  }

  article.destaques .orange {
    border-bottom: 3px solid #F16F31;
  }

  article.destaques .orange:hover {
    background: #F16F31;
    transition: all .3s ease;
  }

  article.destaques .orange:hover i.box-orange {
    background: #FFF;
    transition: all .3s ease;
  }

  article.destaques .orange:hover h3,
  article.destaques .orange:hover p {
    color: #FFF;
    transition: all .3s ease;
  }

  article.destaques .roxo-b {
    border-bottom: 3px solid #623F99;
  }

  article.destaques .roxo-b:hover {
    background: #623F99;
    transition: all .3s ease;
  }

  article.destaques .roxo-b:hover i.box-roxo-b {
    background: #FFF;
    transition: all .3s ease;
  }

  article.destaques .roxo-b:hover h3,
  article.destaques .roxo-b:hover p {
    color: #FFF;
    transition: all .3s ease;
  }

  article.destaques .coral {
    border-bottom: 3px solid #F04B5D;
  }

  article.destaques .coral:hover {
    background: #F04B5D;
    transition: all .3s ease;
  }

  article.destaques .coral:hover i.box-coral {
    background: #FFF;
    transition: all .3s ease;
  }

  article.destaques .coral:hover h3,
  article.destaques .coral:hover p {
    color: #FFF;
    transition: all .3s ease;
  }
}

@media only screen and (max-width: 992px) {
  article div.line {
    width: 35%;
    height: 2px;

    display: block;
    margin: 0 auto;
    position: relative;

    border: 2px solid #F16F31;
    border-radius: 16px;
  }

  article div.line::after,
  article div.line::before {
    content: '';
    position: absolute;
    top: -6px;
    left: 50%;
    transform: translateX(-50%);
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid #ED692C;

    border-radius: 4px;
  }

  article.hours .hours-box {
    display: flex;
    align-items: center;
    margin: 10px 0;
    padding: 30px 15px;
    background: #FFF;
    border-top-left-radius: 32px;
    border-bottom-right-radius: 32px;
    border-bottom-left-radius: 5px;
    border-top-right-radius: 5px;
    box-shadow: 0px 0px 8px rgba(1, 41, 112, 0.1);
    border: 1px solid rgba(1, 41, 112, 0.15);
  }

  article.hours .hours-box i {
    margin-right: 15px;
    font-size: 32px;
    line-height: 54px;
    letter-spacing: 0;
    padding: 10px 20px;
    border-radius: 4px;
  }

  article.hours .hours-box .text-color {
    color: #888;
  }

  article.hours .hours-box .bg-orange {
    background-color: rgba(241, 111, 49, 0.18);
  }

  article.hours .hours-box .orange {
    color: rgba(241, 111, 49, 1);
  }

  article.hours .hours-box .bg-roxo-b {
    background-color: rgba(98, 63, 153, 0.18);
  }

  article.hours .hours-box .roxo-b {
    color: rgba(98, 63, 153, 1)
  }

  article.hours .hours-box .bg-coral {
    background-color: rgba(240, 75, 93, 0.18);
  }

  article.hours .hours-box .coral {
    color: rgba(240, 75, 93, 1);
  }

  article.hours .hours-box div span {
    font-size: 30px;
    font-weight: 700;
    line-height: 42px;
    letter-spacing: 0;
  }

  article.hours .hours-box div p {
    margin: 0;
    padding: 0;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0;
    text-transform: uppercase;
  }

  article.content-matriz {
    height: 100%;
    background-color: transparent;
    padding: 0 0 50px;
  }

  article.content-matriz .box-matriz {
    width: 100%;
  }

  article.content-matriz .box-matriz>.header_box-matriz {
    padding: 20px 0;
    text-transform: uppercase;
    text-align: center;
  }

  article.content-matriz .box-matriz .header_box-matriz>h2.display-5 {
    font-size: 2.5rem;
    font-weight: 700;
  }

  article.content-matriz .table-desktop {
    display: none;
  }

  article.content-matriz .table-mobile {
    display: block;
  }

  article.content-matriz .table-mobile .info {
    font-size: 0.8em;
    letter-spacing: 0.008em;
    font-weight: 600;
  }

  article.content-matriz .table-mobile .button-collapse {
    border-radius: 5px;
    background-color: #F16F31;
    border: 1px solid #F16F31;
    font-weight: 700;
  }

  article.content-matriz .table-mobile .button-collapse:focus,
  article.content-matriz .table-mobile .button-collapse:active {
    box-shadow: 0rem 0.005rem 0.4rem #ED550F;
    border: 1px solid #F16F31;
  }

  article.content-matriz .table-mobile div.header,
  article.content-matriz .table-mobile div.body-table,
  article.content-matriz .table-mobile div.footer-table {
    padding: 0px 15px;
  }

  article.content-matriz .table-mobile div.header div.materias {
    padding: 5px 10px;
    
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0;

    color: #FFF;
    
    background-color: #F16F31;
    
    border: 1px solid #F16F31;
    border-bottom-right-radius: 10px;
    border-top-left-radius: 10px;
  }

  article.content-matriz .table-mobile div.header div.aulas {
    padding: 5px 10px;
    
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0;
    
    color: #F16F31;
    text-align: center !important;
    
    background-color: #E8E8E8;
    
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  article.content-matriz .table-mobile div.body-table {
    border-left: 1px solid #E0E0E0;
    border-right: 1px solid #E0E0E0;
    padding: 0px 15px;

    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0;
    color: #555555;
    text-transform: uppercase;
  }

  article.content-matriz .table-mobile div.body-table div.bg-lightwhite {
    background-color: #F6F6F6;
    padding: 10px 5px;
    border-bottom: 1px solid #E0E0E0;
  }

  article.content-matriz .table-mobile div.body-table div.bg-white {
    padding: 10px 5px;
    /* border-top: 1px solid #E0E0E0; */
    border-bottom: 1px solid #E0E0E0;
  }

  article.content-matriz .table-mobile div.body-table div.materia {
    font-weight: 500;
    display: flex;
    align-items: center;
  }

  article.content-matriz .table-mobile div.body-table div.aulas {
    text-align: center;
    font-weight: 600;
    color: #F16F31;
  }

  article.content-matriz .table-mobile div.footer-table div.materia {
    font-weight: 700;
    color: #F16F31;
    background-color: #E8E8E8;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    padding: 5px 10px;
  }

  article.content-matriz .table-mobile div.footer-table div.aulas {
    font-weight: 700;
    color: #FFF;
    border-top-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border: 1px solid #F16F31;
    background-color: #F16F31;
    padding: 5px 10px;
    text-align: center;
  }

  article.agendamento {
    background-color: #F16F31;
    background-size: cover;
    padding: 30px 0;
  }

  article.agendamento h2.display-5 {
    color: #FFF;
    font-size: 2.5rem;
    font-weight: 700;
    line-height: 52px;
    letter-spacing: 0;

    text-transform: uppercase;
  }

  article.agendamento p.lead {
    color: #FFF;
    font-size: 1.25rem;
    font-weight: 300;
    line-height: 26px;
    letter-spacing: 0;
  }

  article.agendamento .btn-admissao {
    min-width: 64px;
    height: 36px;

    display: inline-block;

    margin: 0px 0;
    padding-left: 16px;
    padding-right: 16px;

    white-space: nowrap;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;

    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0;

    text-transform: uppercase;
    color: #FFF;

    background-image: none;
    background: linear-gradient(90deg, #FFF 0%, #FFF 50%, transparent 50%, transparent 100%);
    background-size: 200% 100%;
    background-position: right;

    transition: background color 0.5s ease;

    border-top-left-radius: 16px;
    border-bottom-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 16px;

    border: 1px solid #FFF;
  }

  article.agendamento .btn-admissao:hover {
    background-position: left;
    transition: all 0.5s ease;
    color: #F16F31;
  }

  article.destaques {
    padding: 60px 16px;
  }

  article.destaques .container-destaque {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-left: 0;
  }

  article.destaques .container-destaque .destaque-header h2.display-5 {
    font-size: 2.5rem;
    font-weight: 700;
    line-height: 52px;
    letter-spacing: 0;
    text-transform: uppercase;
    text-align: center;
  }

  article.destaques .container-destaque .destaque-box {
    height: 100%;
    padding: 60px 30px;
    text-align: center;
    transition: all .3s ease;

    border-top-left-radius: 32px;
    border-bottom-right-radius: 32px;
    border-bottom-left-radius: 5px;
    border-top-right-radius: 5px;

    border: 1px solid rgba(1, 41, 112, 0.15);
    box-shadow: 0px 0px 8px rgba(1, 41, 112, 0.1);
  }

  article.destaques .container-destaque .destaque-box i.icon {
    font-size: 36px;
    padding: 40px 20px;
    border-radius: 4px;
    position: relative;
    margin-bottom: 25px;
    display: inline-block;
    line-height: 0;
    transition: 0.3s;
  }

  article.destaques .container-destaque .destaque-box h3 {
    margin: 0 0 8px;
    font-size: 1.75rem;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: 0;
    color: #333;
  }

  article.destaques .container-destaque .destaque-box p {
    margin: 0 0 1rem;
  }

  article.destaques .container-destaque .destaque-box .box-orange {
    background-color: rgba(241, 111, 49, 0.18);
    color: #f16f31;
  }

  article.destaques .container-destaque .destaque-box .box-roxo-b {
    background-color: rgba(59, 25, 83, 0.18);
  }

  article.destaques .container-destaque .destaque-box .box-coral {
    background-color: rgba(240, 75, 93, 0.18);
    color: #f04b5d;
  }

  article.destaques .orange {
    border-bottom: 3px solid #F16F31;
  }

  article.destaques .orange:hover {
    background: #F16F31;
    transition: all .3s ease;
  }

  article.destaques .orange:hover i.box-orange {
    background: #FFF;
    transition: all .3s ease;
  }

  article.destaques .orange:hover h3,
  article.destaques .orange:hover p {
    color: #FFF;
    transition: all .3s ease;
  }

  article.destaques .roxo-b {
    border-bottom: 3px solid #623F99;
  }

  article.destaques .roxo-b:hover {
    background: #623F99;
    transition: all .3s ease;
  }

  article.destaques .roxo-b:hover i.box-roxo-b {
    background: #FFF;
    transition: all .3s ease;
  }

  article.destaques .roxo-b:hover h3,
  article.destaques .roxo-b:hover p {
    color: #FFF;
    transition: all .3s ease;
  }

  article.destaques .coral {
    border-bottom: 3px solid #F04B5D;
  }

  article.destaques .coral:hover {
    background: #F04B5D;
    transition: all .3s ease;
  }

  article.destaques .coral:hover i.box-coral {
    background: #FFF;
    transition: all .3s ease;
  }

  article.destaques .coral:hover h3,
  article.destaques .coral:hover p {
    color: #FFF;
    transition: all .3s ease;
  }
}
</style>
