import Colegio from "@/views/Index";
import Fundamental from "@/views/Fundamental";
import Medio from "@/views/Medio";
import TerceiraSerie from "@/views/TerceiraSerie";
import AdmissaoDeAlunos from "@/views/admissao-de-alunos";
import Matricula from "@/views/matricula";
import About from "@/views/quem-somos";
import BasqueteMasculino from "@/views/AtividadesComplementares/BasqueteMasculino";
import Error404 from "@/views/error404";

const routes = [
  {
    path: "/",
    name: "home-colegio",
    component: Colegio,
    meta: { section_name: "colegio" },
  },
  {
    path: "/fundamental_anos-finais",
    name: "fundamentalAnosFinais",
    component: Fundamental,
    meta: { section_name: "colegio", title: "Ensino Fundamental • Anos Finais" },
  },
  {
    path: "/ensino_medio",
    name: "ensinoMedio",
    component: Medio,
    meta: { section_name: "colegio", title: "Ensino Médio" },
  },
  {
    path: "/3a_serie",
    name: "3aSerie",
    component: TerceiraSerie,
    meta: { section_name: "colegio", title: "3ª Série • Ensino Médio" },
  },
  {
    path: "/alfred",
    name: "alfred",
    beforeEnter: () => {
      location.href = "https://alfred.com.br/";
    },
  },
  {
    path: "/classapp",
    name: "classapp",
    beforeEnter: () => {
      location.href = "https://classapp.com.br/";
    },
  },
  {
    path: "/classroom",
    name: "classroom",
    beforeEnter: () => {
      location.href = "https://classroom.google.com/h";
    },
  },
  {
    path: "/lyceum",
    name: "lyceum",
    beforeEnter: () => {
      location.href = "http://academico.cpv.com.br:9090/aluno";
    },
  },
  {
    path: "/carreiras",
    name: "carreiras",
    beforeEnter: () => {
      location.href = "https://cpv.com.br/carreiras";
    },
  },
  {
    path: "/contato",
    name: "contato",
    beforeEnter: () => {
      location.href = "https://cpv.com.br/contato";
    },
  },
  {
    path: "/admissao-de-novos-alunos",
    name: "admissao-de-novos-alunos",
    component: AdmissaoDeAlunos,
    meta: { section_name: "colegio", title: "Admissão de Alunos" },
  },

  {
    path: "/matricula",
    name: "matricula",
    component: Matricula,
    meta: { section_name: "colegio", title: "Matrícula" },
  },
  // {
  //   path: "/grupo-cpv",
  //   name: "grupo-cpv",
  //   component: quemSomos,
  //   meta: { section_name: "grupo" },
  // },
  {
    path: "/cursinho",
    name: "cursinho",
    beforeEnter() {
      location.href = "https://cursinho.cpv.com.br";
    },
  },

  {
    path: "/basquete-masculino",
    name: "basquete-masculino",
    component: BasqueteMasculino,
    meta: { section_name: "colegio" },
  },
  {
    path: "/quem-somos",
    name: "quem-somos",
    component: About,
    meta: { section_name: "colegio", title: "Quem Somos" },
  },
  {
    path: "*",
    name: "Error404",
    component: Error404,
    meta: { section_name: "colegio" },
  },
  {
    path: "/error404-*",
  },
];
export default routes;
