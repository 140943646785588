<template>
  <section class="animate__animated animate__fadeIn">
    <HeaderTitle :title="'Ensino Médio'" />
    <BreadCpv />

    <article>
      <b-container fluid="lg">
        <div class="d-md-flex align-items-md-center justify-content-md-baseline">
          <div class="mr-md-auto">
            <p>
              Nessa etapa final do aprofundamento e complementação dos conteúdos da educação básica, focamos em dois
              grandes pilares. Uma formação acadêmica forte e competitiva, technical skills, voltada ao acesso às
              universidades de renome e outra na consolidação das habilidades do século XXI, human skills, tão
              necessárias à futura vida profissional do nosso aluno, através de projetos desenvolvidos em duas trilhas
              de Empreendedorismo, em Ciências Humanas e em Ciências da Natureza, das quais o nosso aluno opta por uma.
            </p>
            <div class="d-flex justify-content-center">
              <b-link class="btn btn-admissao">Clique aqui para agendar sua visita</b-link>
            </div>
          </div>
          <img class="ml-md-3 rounded shadow-sm img-intro mt-3 mt-md-0" src="../components/global/image/20180522_100628.jpg" width="100%" height="275px">
        </div>
      </b-container>
    </article>

    <div class="pl-2 pr-2 pl-lg-5 pr-lg-5">
      <article class="hours">
        <div class="header_box-hours mb-4">
          <h2 class="display-5">1ª e 2ª Séries</h2>
          <div class="line"></div>
        </div>
        <b-row>
          <b-col cols="12" md="6" lg="4" v-for="(hours, index) in showHoursMedio.data" :key="index">
            <div class="hours-box">
              <i class="icon" :class="[hours.icon, hours.color, hours.bg_hours]"></i>
              <div>
                <span :class="[hours.color, hours.teste]">{{ hours.hour }}</span>
                <p :class="hours.txt_color">{{ hours.text }}</p>
              </div>
            </div>
          </b-col>
        </b-row>
      </article>
  
      <article class="hours">
        <div class="header_box-hours mb-4">
          <h2 class="display-5">3ª Série</h2>
          <div class="line"></div>
        </div>
        <b-row>
          <b-col cols="12" md="6" lg="4" v-for="(hours, index) in showHoursTerceiraSerie.data" :key="index">
            <div class="hours-box">
              <i class="icon" :class="[hours.icon, hours.color, hours.bg_hours]"></i>
              <div>
                <span :class="[hours.color, hours.teste]">{{ hours.hour }}</span>
                <p :class="hours.txt_color">{{ hours.text }}</p>
              </div>
            </div>
          </b-col>
        </b-row>
      </article>
    </div>

    <article class="content-matriz">
      <b-container class="d-flex justify-content-center">
        <div class="box-matriz">
          <div class="header_box-matriz mb-4">
            <h2 class="display-5">Matriz</h2>
            <div class="line"></div>
          </div>

          <div class="table-mobile">
              <p class="mb-3 info">Clique sobre o Ano Escolar desejado e saiba mais sobre a matriz curricular.</p>
              <b-button v-b-toggle.sexto-ano class="w-100 button-collapse">1º ANO</b-button>
              <b-collapse id="sexto-ano" class="mt-2 mb-2" accordion="my-accordion">
                <div class="header">
                  <b-row>
                    <b-col cols="8" class="materias">Matéria</b-col>
                    <b-col cols="4" class="aulas"> Aulas </b-col>
                  </b-row>
                </div>
                <div class="body-table" v-for="(matriz, index) in showMatriz.data" :key="index">
                  <b-row class="bg-white">
                    <b-col cols="8" class="materia text-capitalize">{{ matriz.name }}</b-col>
                    <b-col
                      cols="4"
                      class="aulas"
                      v-for="(amounts, i) in matriz.amount"
                      :key="i"
                      v-show="amounts.quantityWeekly"
                    >
                      <div v-if="amounts.quantityWeekly >= '1' || amounts.quantityWeekly <= '12'">
                        {{ amounts.quantityWeekly }}
                      </div>
                    </b-col>
                  </b-row>
                </div>
                <div class="footer-table">
                  <b-row class="bg-lightwhite">
                    <b-col cols="8" class="materia">Total</b-col>
                    <b-col cols="4" class="aulas">42 Aulas Semanais</b-col>
                  </b-row>
                </div>
              </b-collapse>
              <b-button v-b-toggle.setimo-ano class="w-100 button-collapse mt-1">2º ANO</b-button>
              <b-collapse id="setimo-ano" class="mt-2 mb-2" accordion="my-accordion">
                <div class="header">
                  <b-row>
                    <b-col cols="8" class="materias">Matéria</b-col>
                    <b-col cols="4" class="aulas"> Aulas </b-col>
                  </b-row>
                </div>
                <div class="body-table" v-for="(matriz, index) in showMatriz.data" :key="index">
                  <b-row class="bg-white">
                    <b-col cols="8" class="materia text-capitalize">{{ matriz.name }}</b-col>
                    <b-col
                      cols="4"
                      class="aulas"
                      v-for="(amounts, i) in matriz.amount"
                      :key="i"
                      v-show="amounts.quantityWeekly"
                    >
                      <div v-if=" amounts.quantityWeekly >= '1' || amounts.quantityWeekly <= '12'">
                        {{ amounts.quantityWeekly }}
                      </div>
                    </b-col>
                  </b-row>
                </div>
                <div class="footer-table">
                  <b-row class="bg-lightwhite">
                    <b-col cols="5" class="materia">Total</b-col>
                    <b-col cols="7" class="aulas">42 Aulas Semanais</b-col>
                  </b-row>
                </div>
              </b-collapse>
              <b-button v-b-toggle.oitavo-ano class="w-100 button-collapse mt-1">3º ANO</b-button>
              <b-collapse id="oitavo-ano" class="mt-2 mb-0" accordion="my-accordion">
                <div class="header">
                  <b-row>
                    <b-col cols="8" class="materias">Matéria</b-col>
                    <b-col cols="4" class="aulas"> Aulas </b-col>
                  </b-row>
                </div>
                <div class="body-table" v-for="(matriz, index) in showMatriz.data" :key="index">
                  <b-row class="bg-white">
                    <b-col cols="8" class="materia text-capitalize">{{ matriz.name }}</b-col>
                    <b-col
                      cols="4"
                      class="aulas"
                      v-for="(amounts, i) in matriz.amount"
                      :key="i"
                      v-show="amounts.quantityWeeklyTerceiro"
                    >
                      <div
                        v-if="
                          amounts.quantityWeeklyTerceiro >= '2' ||
                          amounts.quantityWeeklyTerceiro <= '12'
                        "
                      >
                        {{ amounts.quantityWeeklyTerceiro }}
                      </div>
                    </b-col>
                  </b-row>
                </div>
                <div class="footer-table">
                  <b-row class="bg-lightwhite">
                    <b-col cols="5" class="materia">Total</b-col>
                    <b-col cols="7" class="aulas">43 Aulas Semanais</b-col>
                  </b-row>
                </div>
              </b-collapse>
            </div>

            <div class="table-desktop">
              <table class="w-100">
                <thead>
                  <tr bgcolor="#e8e8e8">
                    <th class="content">Componente Curricular</th>
                    <th class="serie first">1º Série</th>
                    <th class="serie second">2º Série</th>
                    <th class="serie third">3º Série</th>
                  </tr>
                </thead>
                <tbody>
                  <tr bgcolor="#f6f6f6">
                    <td class="content">Língua Portuguesa</td>
                    <td class="amount">4</td>
                    <td class="amount">4</td>
                    <td class="amount">4</td>
                  </tr>
                  <tr>
                    <td class="content">Literatura</td>
                    <td class="amount">2</td>
                    <td class="amount">2</td>
                    <td class="amount">2</td>
                  </tr>
                  <tr bgcolor="#f6f6f6">
                    <td class="content">Redação</td>
                    <td class="amount">2</td>
                    <td class="amount">2</td>
                    <td class="amount">2</td>
                  </tr>
                  <tr>
                    <td class="content">Matemática</td>
                    <td class="amount">4</td>
                    <td class="amount">4</td>
                    <td class="amount">4</td>
                  </tr>
                  <tr bgcolor="#f6f6f6">
                    <td class="content">Geometria</td>
                    <td class="amount">2</td>
                    <td class="amount">2</td>
                    <td class="amount">2</td>
                  </tr>
                  <tr>
                    <td class="content">Biologia</td>
                    <td class="amount">4</td>
                    <td class="amount">4</td>
                    <td class="amount">4</td>
                  </tr>
                  <tr bgcolor="#f6f6f6">
                    <td class="content">Física</td>
                    <td class="amount">4</td>
                    <td class="amount">4</td>
                    <td class="amount">4</td>
                  </tr>
                  <tr>
                    <td class="content">Química</td>
                    <td class="amount">4</td>
                    <td class="amount">4</td>
                    <td class="amount">4</td>
                  </tr>
                  <tr bgcolor="#f6f6f6">
                    <td class="content">História</td>
                    <td class="amount">3</td>
                    <td class="amount">3</td>
                    <td class="amount">3</td>
                  </tr>
                  <tr>
                    <td class="content">Geografia</td>
                    <td class="amount">3</td>
                    <td class="amount">3</td>
                    <td class="amount">3</td>
                  </tr>
                  <tr bgcolor="#f6f6f6">
                    <td class="content">Filosofia</td>
                    <td class="amount">1</td>
                    <td class="amount">1</td>
                    <td class="amount">1</td>
                  </tr>
                  <tr>
                    <td class="content">Sociologia</td>
                    <td class="amount">1</td>
                    <td class="amount">1</td>
                    <td class="amount">1</td>
                  </tr>
                  <tr bgcolor="#f6f6f6">
                    <td class="content">Arte</td>
                    <td class="amount">1</td>
                    <td class="amount">1</td>
                    <td class="amount">1</td>
                  </tr>
                  <tr>
                    <td class="content">Educação Física</td>
                    <td class="amount">1</td>
                    <td class="amount">1</td>
                    <td class="amount"></td>
                  </tr>
                  <tr bgcolor="#f6f6f6">
                    <td class="content">Inglês</td>
                    <td class="amount">2</td>
                    <td class="amount">2</td>
                    <td class="amount"></td>
                  </tr>
                  <tr>
                    <td class="content">Atualidades</td>
                    <td class="amount">2</td>
                    <td class="amount">2</td>
                    <td class="amount"></td>
                  </tr>
                  <tr bgcolor="#f6f6f6">
                    <td class="content no-border">Empreendedorismo</td>
                    <td class="amount no-border">2</td>
                    <td class="amount no-border">2</td>
                    <td class="amount no-border"></td>
                  </tr>
                  <tr>
                    <td class="content no-border">
                      Aprofundamento em: Ciências da Natureza, Ciências Humanas e Matemática.
                    </td>
                    <td class="amount no-border"></td>
                    <td class="amount no-border"></td>
                    <td class="amount no-border">8</td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr bgcolor="#e8e8e8">
                    <th class="serie first">Total</th>
                    <td colspan="2" class="content">42 Aulas Semanais</td>
                    <td class="serie second">43 Aulas Semanais</td>
                  </tr>
                </tfoot>
              </table>
          </div>
        </div>
      </b-container>
    </article>

    <article class="agendamento">
      <b-container>
        <div class="text-center">
          <h2 class="display-5">Admissão de novos alunos</h2>
          <p class="lead">Agende sua visita e venha conhecer nossa escola!</p>
          <b-link class="btn btn-admissao" to="/admissao-de-novos-alunos">Clique aqui para agendar a sua visita</b-link>
        </div>
      </b-container>
    </article>

    <article class="destaques">
      <div class="container-destaque">
        <header class="destaque-header">
          <h2 class="display-5">Destaques CPV</h2>
        </header>
        <div class="line my-3 mb-5">
          <div class="triangle"></div>
        </div>
        <b-row>
          <b-col class="mb-4" cols="12" sm="12" md="6" lg="4" xl="4" v-for="(destaques, index) in showDestaques.data"
            :key="index">
            <div class="destaque-box" v-bind:class="destaques.color">
              <i class="icon" :class="[destaques.icon, destaques.box_icon]"></i>
              <h3>{{ destaques.name }}</h3>
              <p>{{ destaques.info }}</p>
            </div>
          </b-col>
        </b-row>
      </div>
    </article>
  </section>
</template>
<script>
import HeaderTitle from '@/components/global/headerTitle.vue';
import BreadCpv from '@/components/global/bread-cpv.vue';
export default {
  components: { HeaderTitle, BreadCpv },
  data: function () {
    return {
      showHoursMedio: {
        data: [
        {
            hour: "42",
            text: "Aulas semanais",
            icon: "fa-duotone fa-calendars",
            color: "orange",
            bg_hours: "bg-orange",
            txt_color: "text-color",
          },
          {
            hour: "8h às 16h30",
            text: "Período integral",
            icon: "fa-duotone fa-clock-eight",
            color: "roxo-b",
            bg_hours: "bg-roxo-b",
            txt_color: "text-color",
          },
          {
            hour: "16h30 às 18h",
            text: "Atividades Extras",
            icon: "fa-duotone fa-solid fa-futbol",
            color: "coral",
            bg_hours: "bg-coral",
            txt_color: "text-color",
          },
        ]
      },
      showHoursTerceiraSerie: {
        data: [
        {
            hour: "43",
            text: "Aulas semanais",
            icon: "fa-duotone fa-calendars",
            color: "orange",
            bg_hours: "bg-orange",
            txt_color: "text-color",
          },
          {
            hour: "7h15 às 15h45",
            text: "Período integral",
            icon: "fa-duotone fa-solid fa-clock-eight",
            color: "roxo-b",
            bg_hours: "bg-roxo-b",
            txt_color: "text-color",
          },
          {
            hour: "15h45 às 18h",
            text: "Atividades Extras",
            icon: "fa-duotone fa-solid fa-futbol",
            color: "coral",
            bg_hours: "bg-coral",
            txt_color: "text-color",
          },
        ]
      },
      showMatriz: {
        data: [
          {
            name: "Língua Portuguesa",
            amount: [{ quantityWeekly: "4" }, { quantityWeeklyTerceiro: "4" }],
            schoolYear: [{ name: "1º Série" }, { name: "2º Série" }, { name: "3º Série" }],
          },
          {
            name: "Literatura",
            amount: [{ quantityWeekly: "2" }, { quantityWeeklyTerceiro: "2" }],
            schoolYear: [{ name: "1º Série" }, { name: "2º Série" }, { name: "3º Série" }],
          },
          {
            name: "Redação",
            amount: [{ quantityWeekly: "2" }, { quantityWeeklyTerceiro: "2" }],
            schoolYear: [{ name: "1º Série" }, { name: "2º Série" }, { name: "3º Série" }],
          },
          {
            name: "Matemática",
            amount: [{ quantityWeekly: "4" }, { quantityWeeklyTerceiro: "4" }],
            schoolYear: [{ name: "1º Série" }, { name: "2º Série" }, { name: "3º Série" }],
          },
          {
            name: "Geometria",
            amount: [{ quantityWeekly: "2" }, { quantityWeeklyTerceiro: "2" }],
            schoolYear: [{ name: "1º Série" }, { name: "2º Série" }, { name: "3º Série" }],
          },
          {
            name: "Biologia",
            amount: [{ quantityWeekly: "4" }, { quantityWeeklyTerceiro: "4" }],
            schoolYear: [{ name: "1º Série" }, { name: "2º Série" }, { name: "3º Série" }],
          },
          {
            name: "Física",
            amount: [{ quantityWeekly: "4" }, { quantityWeeklyTerceiro: "4" }],
            schoolYear: [{ name: "1º Série" }, { name: "2º Série" }, { name: "3º Série" }],
          },
          {
            name: "Química",
            amount: [{ quantityWeekly: "4" }, { quantityWeeklyTerceiro: "4" }],
            schoolYear: [{ name: "1º Série" }, { name: "2º Série" }, { name: "3º Série" }],
          },
          {
            name: "História",
            amount: [{ quantityWeekly: "3" }, { quantityWeeklyTerceiro: "3" }],
            schoolYear: [{ name: "1º Série" }, { name: "2º Série" }, { name: "3º Série" }],
          },
          {
            name: "Geografia",
            amount: [{ quantityWeekly: "3" }, { quantityWeeklyTerceiro: "3" }],
            schoolYear: [{ name: "1º Série" }, { name: "2º Série" }, { name: "3º Série" }],
          },
          {
            name: "Filosofia",
            amount: [{ quantityWeekly: "1" }, { quantityWeeklyTerceiro: "1" }],
            schoolYear: [{ name: "1º Série" }, { name: "2º Série" }, { name: "3º Série" }],
          },
          {
            name: "Sociologia",
            amount: [{ quantityWeekly: "1" }, { quantityWeeklyTerceiro: "1" }],
            schoolYear: [{ name: "1º Série" }, { name: "2º Série" }, { name: "3º Série" }],
          },
          {
            name: "Arte",
            amount: [{ quantityWeekly: "1" }, { quantityWeeklyTerceiro: "1" }],
            schoolYear: [{ name: "1º Série" }, { name: "2º Série" }, { name: "3º Série" }],
          },
          {
            name: "Educação Física",
            amount: [{ quantityWeekly: "1" }, { quantityWeeklyTerceiro: "" }],
            schoolYear: [{ name: "1º Série" }, { name: "2º Série" }, { name: "3º Série" }],
          },
          {
            name: "Inglês",
            amount: [{ quantityWeekly: "2" }, { quantityWeeklyTerceiro: "" }],
            schoolYear: [{ name: "1º Série" }, { name: "2º Série" }, { name: "3º Série" }],
          },
          {
            name: "Atualidades",
            amount: [{ quantityWeekly: "2" }, { quantityWeeklyTerceiro: "" }],
            schoolYear: [{ name: "1º Série" }, { name: "2º Série" }, { name: "3º Série" }],
          },
          {
            name: "Empreendedorismo",
            amount: [{ quantityWeekly: "2" }, { quantityWeeklyTerceiro: "" }],
            schoolYear: [{ name: "1º Série" }, { name: "2º Série" }, { name: "3º Série" }],
          },
          {
            name: "Aprof. em C. da Natureza, C. Humanas e Matemática",
            amount: [{ quantityWeekly: "" }, { quantityWeeklyTerceiro: "8" }],
            schoolYear: [{ name: "1º Série" }, { name: "2º Série" }, { name: "3º Série" }],
          },
        ],
      },
      showDestaques: {
        data: [
        {
            name: "Língua Inglesa",
            info: "No ensino médio o ensino da língua inglesa é realizado com 2 aulas semanais, com turmas separadas por nível de conhecimento, completando a formação e preparando aos Certificados Cambridge (UK).",
            icon: "fa-duotone fa-file-certificate",
            color: "orange",
            box_icon: "box-orange",
          },
          {
            name: "Apoio Pedagógico",
            info: "No apoio pedagógico as dúvidas são esclarecidas e os conteúdos reforçados com o objetivo de suprir possíveis defasagens de aprendizado e permitir o acompanhamento do conteúdo das aulas com qualidade.",
            icon: "fa-duotone fa-handshake",
            color: "roxo-b",
            box_icon: "box-roxo-b",
          },
          {
            name: "Empreendedorismo",
            info: "Empreender é aprender a criar, resolver problemas e trabalhar em equipe. Os alunos desenvolvem habilidades do século XXI, aplicando metodologias ágeis em projetos reais, equilibrando metas financeiras com responsabilidade social e ambiental, e se preparando para os desafios do futuro.",
            icon: "fa-duotone fa-briefcase",
            color: "coral",
            box_icon: "box-coral",
          },
          {
            name: "Professores Assistentes",
            info: "Professores que acompanham o processo educativo, dando atendimento individualizado ao aluno, esclarecendo as dúvidas, orientando os estudos ou retomando determinados conteúdos.",
            icon: "fa-duotone fa-user-tie",
            color: "roxo-b",
            box_icon: "box-roxo-b",
          },
          {
            name: "Alfred",
            info: "Instrumento de personalização e acompanhamento do aprendizado, a Plataforma monitora os resultados das tarefas de aula, produções de textos, provas e simulados, permitindo o acompanhamento e a análise individualizada do desempenho escolar, bem como a auto regulação por parte do aluno.",
            icon: "fa-duotone fa-graduation-cap",
            color: "coral",
            box_icon: "box-coral",
          },
          {
            name: "Material Didático",
            info: "O material didático foi desenvolvido observando a proposta educacional do Colégio CPV, facilitando a integração das várias áreas do conhecimento e um aprendizado mais estruturado e intenso.",
            icon: "fa-duotone fa-books",
            color: "orange",
            box_icon: "box-orange",
          },
          {
            name: "Revisão",
            info: "No segundo semestre da terceira série, os alunos fazem uma revisão de todo o ensino médio com foco nos grandes vestibulares, em especial a Fuvest e o ENEM, garantindo a todos uma preparação robusta à aprovação da universidade desejada.",
            icon: "fa-duotone fa-file-pen",
            color: "coral",
            box_icon: "box-coral",
          },
        ],
      },
    }
  }
}
</script>
<style scoped>
article {
  padding: 30px 0;
  margin: 30px 0;
}

article p {
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0;
}

article .img-intro {
  border: 4px solid;
  border-image: linear-gradient(45deg, #3A1C53, #F16F31, #F04B5D, #623F99) 2 round;
}

article .btn-admissao {
  min-width: 64px;
  height: 36px;

  display: inline-block;

  margin: 30px 0 0;
  padding-left: 16px;
  padding-right: 16px;

  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0;

  text-transform: uppercase;
  color: #FFF;

  background-image: none;
  background: linear-gradient(90deg, #F55911 0%, #F55911 50%, #F16F31 50%, #F16F31 100%);
  background-size: 200% 100%;
  background-position: right;

  transition: background 0.5s ease;

  border-top-left-radius: 16px;
  border-bottom-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 16px;
}

article .btn-admissao:hover {
  background-position: left;
  transition: all 0.5s ease;
}

@media (min-width: 992px) {
  article div.line {
    width: 10%;
    height: 2px;

    display: block;
    margin: 0 auto;
    position: relative;

    border: 2px solid #F16F31;
    border-radius: 16px;
  }

  article div.line::after,
  article div.line::before {
    content: '';
    position: absolute;
    top: -6px;
    left: 50%;
    transform: translateX(-50%);
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid #ED692C;

    border-radius: 4px;
  }

  article.hours {
    padding: 30px 0;
  }

  article.hours .hours-box {
    display: flex;
    align-items: center;
    padding: 30px 15px;
    background: #FFF;
    border-top-left-radius: 32px;
    border-bottom-right-radius: 32px;
    border-bottom-left-radius: 5px;
    border-top-right-radius: 5px;
    box-shadow: 0px 0px 8px rgba(1, 41, 112, 0.18);
    border: 1px solid rgba(1, 41, 112, 0.15);
  }

  article.hours .hours-box i {
    margin-right: 15px;
    font-size: 42px;
    line-height: 54px;
    letter-spacing: 0;
    padding: 10px 20px;
    border-radius: 4px;
  }

  article.hours .hours-box .text-color {
    color: #888;
  }

  article.hours .hours-box .bg-orange {
    background-color: rgba(241, 111, 49, 0.18);
  }

  article.hours .hours-box .orange {
    color: rgba(241, 111, 49, 1);
  }

  article.hours .hours-box .bg-roxo-b {
    background-color: rgba(98, 63, 153, 0.18);
  }

  article.hours .hours-box .roxo-b {
    color: #623f99
  }

  article.hours .hours-box .bg-coral {
    background-color: rgba(240, 75, 93, 0.18);
  }

  article.hours .hours-box .coral {
    color: #f04b5d;
  }

  article.hours .hours-box div span {
    font-size: 34px;
    font-weight: 700;
    line-height: 42px;
    letter-spacing: 0;
  }

  article.hours .hours-box div p {
    margin: 0;
    padding: 0;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0;
    text-transform: uppercase;
  }

  article.content-matriz {
    height: 100%;
    background-color: transparent;
    padding: 0 0 50px;
  }

  article.content-matriz .box-matriz {
    width: 100%;
  }

  article.hours .header_box-hours>h2.display-5, 
  article.content-matriz .box-matriz>.header_box-matriz {
    text-transform: uppercase;
    text-align: center;
  }

  article.hours .header_box-hours>h2.display-5,
  article.content-matriz .box-matriz .header_box-matriz>h2.display-5 {
    font-size: 2.5rem;
    font-weight: 700;
  }

  article.content-matriz .table-mobile {
    display: none;
  }

  article.content-matriz .table-desktop>table {
    width: 100%;
    border: 0;
  }

  article.content-matriz .table-desktop>table thead tr th {
    color: #F6F6F6;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0;
  }

  article.content-matriz .table-desktop>table thead tr th.content {
    height: 36px;
    padding-left: 16px;
    padding-right: 16px;
    background-color: #F16F31;
    border-top-left-radius: 16px;
    border-bottom-right-radius: 16px;
    text-transform: uppercase;
  }

  article.content-matriz .table-desktop>table thead tr th.serie {
    text-align: center;
    color: #F16F31;
    text-transform: uppercase;
  }

  article.content-matriz .table-desktop>table thead tr th.first,
  article.content-matriz .table-desktop>table thead tr th.third {
    border-bottom-left-radius: 16px;
    border-top-right-radius: 16px;
  }

  article.content-matriz .table-desktop>table thead tr th.second,
  article.content-matriz .table-desktop>table thead tr th.fourth {
    border-bottom-right-radius: 16px;
    border-top-left-radius: 16px;
  }

  article.content-matriz .table-desktop>table tbody tr td {
    height: 36px;

    padding-left: 16px;
    padding-right: 16px;

    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0;

    color: #767676;

    border-bottom: 1px solid #e8e8e8;
  }

  article.content-matriz .table-desktop>table tbody tr td.content {
    padding-left: 16px;
    padding-right: 16px;
    font-weight: 500;
  }

  article.content-matriz .table-desktop>table tbody tr td.amount {
    text-align: center;
    border-right: 1px solid #E8E8E8;
    border-left: 1px solid #E8E8E8;
    font-weight: 600;
  }

  article.content-matriz .table-desktop>table tbody tr td.no-border {
    border-bottom: 0;
  }

  article.content-matriz .table-desktop>table tfoot tr {
    margin: 30px 0 0;
  }

  article.content-matriz .table-desktop>table tfoot tr td,
  article.content-matriz .table-desktop>table tfoot tr th {
    height: 36px;
    padding-left: 16px;
    padding-right: 16px;

    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0;
  }

  article.content-matriz .table-desktop>table tfoot tr th.serie {
    color: #F16F31;
    text-transform: uppercase;

    border-bottom-left-radius: 16px;
    border-top-right-radius: 16px;
  }

  article.content-matriz .table-desktop>table tfoot tr td.content {
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0;

    text-align: center;
    text-transform: uppercase;

    color: #F6F6F6;
    background-color: #F16F31;

    border-top-left-radius: 16px;
    border-bottom-right-radius: 16px;
  }
  article.content-matriz .table-desktop>table tfoot tr td.serie.second {
    width: 16%;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0;

    text-align: center;
    text-transform: uppercase;

    color: #F16F31;
    background-color: #E8E8E8;

    border-bottom-right-radius: 16px;
    border-bottom-left-radius: 16px;
  }

  article.agendamento {
    background-color: #F16F31;
    background-size: cover;
    padding: 30px 0;
  }

  article.agendamento h2.display-5 {
    color: #FFF;
    font-size: 2.5rem;
    font-weight: 700;
    line-height: 52px;
    letter-spacing: 0;

    text-transform: uppercase;
  }

  article.agendamento p.lead {
    color: #FFF;
    font-size: 1.25rem;
    font-weight: 300;
    line-height: 26px;
    letter-spacing: 0;
  }

  article.agendamento .btn-admissao {
    min-width: 64px;
    height: 36px;

    display: inline-block;

    margin: 0px 0;
    padding-left: 16px;
    padding-right: 16px;

    white-space: nowrap;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;

    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0;

    text-transform: uppercase;
    color: #FFF;

    background-image: none;
    background: linear-gradient(90deg, #FFF 0%, #FFF 50%, transparent 50%, transparent 100%);
    background-size: 200% 100%;
    background-position: right;

    transition: background color 0.5s ease;

    border-top-left-radius: 16px;
    border-bottom-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 16px;

    border: 1px solid #FFF;
  }

  article.agendamento .btn-admissao:hover {
    background-position: left;
    transition: all 0.5s ease;
    color: #F16F31;
  }

  article.destaques {
    padding: 30px 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }

  article.destaques .container-destaque {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    justify-content: center;
  }

  article.destaques .container-destaque>.destaque-header>h2.display-5 {
    font-size: 2.5rem;
    font-weight: 700;
    line-height: 52px;
    letter-spacing: 0;

    text-transform: uppercase;
  }

  article.destaques .container-destaque .destaque-box {
    box-shadow: 0 0 15px rgba(1, 41, 112, 0.18);
    height: 100%;
    padding: 60px 15px;
    text-align: center;
    transition: 0.3s;
    border-top-left-radius: 32px;
    border-bottom-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 32px;
  }

  article.destaques .container-destaque .destaque-box>i.icon {
    width: 80px;
    height: 80px;
    margin-bottom: 25px;

    display: flex;
    align-items: center;
    justify-content: center;

    margin: 0 auto 25px;
    position: relative;

    font-size: 36px;
    line-height: 44px;

    transition: 0.3s;
    border-radius: 5px;
  }

  article.destaques .container-destaque .destaque-box>i.box-orange {
    background-color: rgba(241, 111, 49, 0.18);
    color: #f16f31;
  }

  article.destaques .container-destaque .destaque-box>i.box-roxo-b {
    background-color: rgba(98, 64, 153, 0.18);
    color: #624099;
  }

  article.destaques .container-destaque .destaque-box>i.box-coral {
    background-color: rgba(240, 75, 93, 0.18);
    color: #f04b5d;
  }

  article.destaques .container-destaque .destaque-box>h3 {
    margin: 0 0 8px;
    color: #333;
    font-size: 28px;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: 0;
  }

  article.destaques .container-destaque .destaque-box>p {
    margin: 0 0 16px;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0;
  }

  article.destaques .orange {
    border-bottom: 3px solid #F16F31;
  }

  article.destaques .orange:hover {
    background: #F16F31;
    transition: all .3s ease;
  }

  article.destaques .orange:hover i.box-orange {
    background: #FFF;
    transition: all .3s ease;
  }

  article.destaques .orange:hover h3,
  article.destaques .orange:hover p {
    color: #FFF;
    transition: all .3s ease;
  }

  article.destaques .roxo-b {
    border-bottom: 3px solid #623F99;
  }

  article.destaques .roxo-b:hover {
    background: #623F99;
    transition: all .3s ease;
  }

  article.destaques .roxo-b:hover i.box-roxo-b {
    background: #FFF;
    transition: all .3s ease;
  }

  article.destaques .roxo-b:hover h3,
  article.destaques .roxo-b:hover p {
    color: #FFF;
    transition: all .3s ease;
  }

  article.destaques .coral {
    border-bottom: 3px solid #F04B5D;
  }

  article.destaques .coral:hover {
    background: #F04B5D;
    transition: all .3s ease;
  }

  article.destaques .coral:hover i.box-coral {
    background: #FFF;
    transition: all .3s ease;
  }

  article.destaques .coral:hover h3,
  article.destaques .coral:hover p {
    color: #FFF;
    transition: all .3s ease;
  }
}

@media only screen and (max-device-width: 992px) {
  article div.line {
    width: 35%;
    height: 2px;

    display: block;
    margin: 0 auto;
    position: relative;

    border: 2px solid #F16F31;
    border-radius: 16px;
  }

  article div.line::after,
  article div.line::before {
    content: '';
    position: absolute;
    top: -6px;
    left: 50%;
    transform: translateX(-50%);
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid #ED692C;

    border-radius: 4px;
  }

  article.hours .hours-box {
    display: flex;
    align-items: center;
    margin: 10px 0;
    padding: 30px 15px;
    background: #FFF;
    border-top-left-radius: 32px;
    border-bottom-right-radius: 32px;
    border-bottom-left-radius: 5px;
    border-top-right-radius: 5px;
    box-shadow: 0px 0px 8px rgba(1, 41, 112, 0.1);
    border: 1px solid rgba(1, 41, 112, 0.15);
  }

  article.hours .hours-box i {
    margin-right: 15px;
    font-size: 32px;
    line-height: 54px;
    letter-spacing: 0;
    padding: 10px 20px;
    border-radius: 4px;
  }

  article.hours .hours-box .text-color {
    color: #888;
  }

  article.hours .hours-box .bg-orange {
    background-color: rgba(241, 111, 49, 0.18);
  }

  article.hours .hours-box .orange {
    color: rgba(241, 111, 49, 1);
  }

  article.hours .hours-box .bg-roxo-b {
    background-color: rgba(98, 63, 153, 0.18);
  }

  article.hours .hours-box .roxo-b {
    color: rgba(98, 63, 153, 1)
  }

  article.hours .hours-box .bg-coral {
    background-color: rgba(240, 75, 93, 0.18);
  }

  article.hours .hours-box .coral {
    color: rgba(240, 75, 93, 1);
  }

  article.hours .hours-box div span {
    font-size: 30px;
    font-weight: 700;
    line-height: 42px;
    letter-spacing: 0;
  }

  article.hours .hours-box div p {
    margin: 0;
    padding: 0;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0;
    text-transform: uppercase;
  }

  article.content-matriz {
    height: 100%;
    background-color: transparent;
    padding: 0 0 50px;
  }

  article.content-matriz .box-matriz {
    width: 100%;
  }

  article.hours .header_box-hours>h2.display-5, 
  article.content-matriz .box-matriz>.header_box-matriz {
    text-transform: uppercase;
    text-align: center;
  }

  article.hours .header_box-hours>h2.display-5,
  article.content-matriz .box-matriz .header_box-matriz>h2.display-5 {
    font-size: 2.5rem;
    font-weight: 700;
  }

  article.content-matriz .table-desktop {
    display: none;
  }

  article.content-matriz .table-mobile {
    display: block;
  }

  article.content-matriz .table-mobile .info {
    font-size: 0.8em;
    letter-spacing: 0.008em;
    font-weight: 600;
  }

  article.content-matriz .table-mobile .button-collapse {
    border-radius: 5px;
    background-color: #F16F31;
    border: 1px solid #F16F31;
    font-weight: 700;
  }

  article.content-matriz .table-mobile .button-collapse:focus,
  article.content-matriz .table-mobile .button-collapse:active {
    box-shadow: 0rem 0.005rem 0.4rem #ED550F;
    border: 1px solid #F16F31;
  }

  article.content-matriz .table-mobile div.header,
  article.content-matriz .table-mobile div.body-table,
  article.content-matriz .table-mobile div.footer-table {
    padding: 10px 15px;
  }

  article.content-matriz .table-mobile div.header div.materias {
    padding: 5px 10px;
    
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0;

    color: #FFF;
    
    background-color: #F16F31;
    
    border: 1px solid #F16F31;
    border-bottom-right-radius: 10px;
    border-top-left-radius: 10px;
  }

  article.content-matriz .table-mobile div.header div.aulas {
    text-align: center !important;
    font-weight: 700;
    color: #F16F31;
    background-color: #E8E8E8;
    border-bottom-left-radius: 10px;
    border-top-right-radius: 10px;
    padding: 5px 10px;
  }

  article.content-matriz .table-mobile div.body-table {
    border-left: 1px solid #E0E0E0;
    border-right: 1px solid #E0E0E0;
    padding: 0px 15px;

    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0;
    color: #555555;
    text-transform: uppercase;
  }

  article.content-matriz .table-mobile div.body-table div.bg-lightwhite {
    background-color: #F6F6F6;
    padding: 10px 5px;
    border-bottom: 1px solid #E0E0E0;
  }

  article.content-matriz .table-mobile div.body-table div.bg-white {
    padding: 10px 5px;
    /* border-top: 1px solid #E0E0E0; */
    border-bottom: 1px solid #E0E0E0;
  }

  article.content-matriz .table-mobile div.body-table div.materia {
    font-weight: 500;
    display: flex;
    align-items: center;
  }

  article.content-matriz .table-mobile div.body-table div.aulas {
    text-align: center;
    font-weight: 600;
    color: #F16F31;
  }

  article.content-matriz .table-mobile div.footer-table div.materia {
    font-weight: 700;
    color: #F16F31;
    background-color: #E8E8E8;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    padding: 5px 10px;
  }

  article.content-matriz .table-mobile div.footer-table div.aulas {
    font-weight: 700;
    color: #FFF;
    border-top-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border: 1px solid #F16F31;
    background-color: #F16F31;
    padding: 5px 10px;
    text-align: center;
  }

  article.agendamento {
    background-color: #F16F31;
    background-size: cover;
    padding: 30px 0;
  }

  article.agendamento h2.display-5 {
    color: #FFF;
    font-size: 2.5rem;
    font-weight: 700;
    line-height: 52px;
    letter-spacing: 0;

    text-transform: uppercase;
  }

  article.agendamento p.lead {
    color: #FFF;
    font-size: 1.25rem;
    font-weight: 300;
    line-height: 26px;
    letter-spacing: 0;
  }

  article.agendamento .btn-admissao {
    min-width: 64px;
    height: 36px;

    display: inline-block;

    margin: 0px 0;
    padding-left: 16px;
    padding-right: 16px;

    white-space: nowrap;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;

    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0;

    text-transform: uppercase;
    color: #FFF;

    background-image: none;
    background: linear-gradient(90deg, #FFF 0%, #FFF 50%, transparent 50%, transparent 100%);
    background-size: 200% 100%;
    background-position: right;

    transition: background color 0.5s ease;

    border-top-left-radius: 16px;
    border-bottom-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 16px;

    border: 1px solid #FFF;
  }

  article.agendamento .btn-admissao:hover {
    background-position: left;
    transition: all 0.5s ease;
    color: #F16F31;
  }

  article.destaques {
    padding: 60px 16px;
  }

  article.destaques .container-destaque {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-left: 0;
  }

  article.destaques .container-destaque .destaque-header h2.display-5 {
    font-size: 2.5rem;
    font-weight: 700;
    line-height: 52px;
    letter-spacing: 0;
    text-transform: uppercase;
    text-align: center;
  }

  article.destaques .container-destaque .destaque-box {
    height: 100%;
    padding: 60px 30px;
    text-align: center;
    transition: all .3s ease;

    border-top-left-radius: 32px;
    border-bottom-right-radius: 32px;
    border-bottom-left-radius: 5px;
    border-top-right-radius: 5px;

    border: 1px solid rgba(1, 41, 112, 0.15);
    box-shadow: 0px 0px 8px rgba(1, 41, 112, 0.1);
  }

  article.destaques .container-destaque .destaque-box i.icon {
    font-size: 36px;
    padding: 40px 20px;
    border-radius: 4px;
    position: relative;
    margin-bottom: 25px;
    display: inline-block;
    line-height: 0;
    transition: 0.3s;
  }

  article.destaques .container-destaque .destaque-box h3 {
    margin: 0 0 8px;
    font-size: 1.75rem;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: 0;
    color: #333;
  }

  article.destaques .container-destaque .destaque-box p {
    margin: 0 0 1rem;
  }

  article.destaques .container-destaque .destaque-box .box-orange {
    background-color: rgba(241, 111, 49, 0.18);
    color: #f16f31;
  }

  article.destaques .container-destaque .destaque-box .box-roxo-b {
    background-color: rgba(59, 25, 83, 0.18);
  }

  article.destaques .container-destaque .destaque-box .box-coral {
    background-color: rgba(240, 75, 93, 0.18);
    color: #f04b5d;
  }

  article.destaques .orange {
    border-bottom: 3px solid #F16F31;
  }

  article.destaques .orange:hover {
    background: #F16F31;
    transition: all .3s ease;
  }

  article.destaques .orange:hover i.box-orange {
    background: #FFF;
    transition: all .3s ease;
  }

  article.destaques .orange:hover h3,
  article.destaques .orange:hover p {
    color: #FFF;
    transition: all .3s ease;
  }

  article.destaques .roxo-b {
    border-bottom: 3px solid #623F99;
  }

  article.destaques .roxo-b:hover {
    background: #623F99;
    transition: all .3s ease;
  }

  article.destaques .roxo-b:hover i.box-roxo-b {
    background: #FFF;
    transition: all .3s ease;
  }

  article.destaques .roxo-b:hover h3,
  article.destaques .roxo-b:hover p {
    color: #FFF;
    transition: all .3s ease;
  }

  article.destaques .coral {
    border-bottom: 3px solid #F04B5D;
  }

  article.destaques .coral:hover {
    background: #F04B5D;
    transition: all .3s ease;
  }

  article.destaques .coral:hover i.box-coral {
    background: #FFF;
    transition: all .3s ease;
  }

  article.destaques .coral:hover h3,
  article.destaques .coral:hover p {
    color: #FFF;
    transition: all .3s ease;
  }
}

@media only screen and (max-width: 992px) {
  article div.line {
    width: 35%;
    height: 2px;

    display: block;
    margin: 0 auto;
    position: relative;

    border: 2px solid #F16F31;
    border-radius: 16px;
  }

  article div.line::after,
  article div.line::before {
    content: '';
    position: absolute;
    top: -6px;
    left: 50%;
    transform: translateX(-50%);
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid #ED692C;

    border-radius: 4px;
  }

  article.hours .hours-box {
    display: flex;
    align-items: center;
    margin: 10px 0;
    padding: 30px 15px;
    background: #FFF;
    border-top-left-radius: 32px;
    border-bottom-right-radius: 32px;
    border-bottom-left-radius: 5px;
    border-top-right-radius: 5px;
    box-shadow: 0px 0px 8px rgba(1, 41, 112, 0.1);
    border: 1px solid rgba(1, 41, 112, 0.15);
  }

  article.hours .hours-box i {
    margin-right: 15px;
    font-size: 32px;
    line-height: 54px;
    letter-spacing: 0;
    padding: 10px 20px;
    border-radius: 4px;
  }

  article.hours .hours-box .text-color {
    color: #888;
  }

  article.hours .hours-box .bg-orange {
    background-color: rgba(241, 111, 49, 0.18);
  }

  article.hours .hours-box .orange {
    color: rgba(241, 111, 49, 1);
  }

  article.hours .hours-box .bg-roxo-b {
    background-color: rgba(98, 63, 153, 0.18);
  }

  article.hours .hours-box .roxo-b {
    color: rgba(98, 63, 153, 1)
  }

  article.hours .hours-box .bg-coral {
    background-color: rgba(240, 75, 93, 0.18);
  }

  article.hours .hours-box .coral {
    color: rgba(240, 75, 93, 1);
  }

  article.hours .hours-box div span {
    font-size: 30px;
    font-weight: 700;
    line-height: 42px;
    letter-spacing: 0;
  }

  article.hours .hours-box div p {
    margin: 0;
    padding: 0;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0;
    text-transform: uppercase;
  }

  article.content-matriz {
    height: 100%;
    background-color: transparent;
    padding: 0 0 50px;
  }

  article.content-matriz .box-matriz {
    width: 100%;
  }

  article.hours .header_box-hours>h2.display-5, 
  article.content-matriz .box-matriz>.header_box-matriz {
    padding: 0;
    text-transform: uppercase;
    text-align: center;
  }

  article.hours .header_box-hours>h2.display-5,
  article.content-matriz .box-matriz .header_box-matriz>h2.display-5 {
    font-size: 2.5rem;
    font-weight: 700;
  }

  article.content-matriz .table-desktop {
    display: none;
  }

  article.content-matriz .table-mobile {
    display: block;
  }

  article.content-matriz .table-mobile .info {
    font-size: 0.8em;
    letter-spacing: 0.008em;
    font-weight: 600;
  }

  article.content-matriz .table-mobile .button-collapse {
    border-radius: 5px;
    background-color: #F16F31;
    border: 1px solid #F16F31;
    font-weight: 700;
  }

  article.content-matriz .table-mobile .button-collapse:focus,
  article.content-matriz .table-mobile .button-collapse:active {
    box-shadow: 0rem 0.005rem 0.4rem #ED550F;
    border: 1px solid #F16F31;
  }

  article.content-matriz .table-mobile div.header,
  article.content-matriz .table-mobile div.body-table,
  article.content-matriz .table-mobile div.footer-table {
    padding: 0px 15px;
  }

  article.content-matriz .table-mobile div.header div.materias {
    padding: 5px 10px;
    
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0;

    color: #FFF;
    
    background-color: #F16F31;
    
    border: 1px solid #F16F31;
    border-bottom-right-radius: 10px;
    border-top-left-radius: 10px;
  }

  article.content-matriz .table-mobile div.header div.aulas {
    padding: 5px 10px;
    
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0;
    
    color: #F16F31;
    text-align: center !important;
    
    background-color: #E8E8E8;
    
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  article.content-matriz .table-mobile div.body-table {
    border-left: 1px solid #E0E0E0;
    border-right: 1px solid #E0E0E0;
    padding: 0px 15px;

    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0;
    color: #555555;
    text-transform: uppercase;
  }

  article.content-matriz .table-mobile div.body-table div.bg-lightwhite {
    background-color: #F6F6F6;
    padding: 10px 5px;
    border-bottom: 1px solid #E0E0E0;
  }

  article.content-matriz .table-mobile div.body-table div.bg-white {
    padding: 10px 5px;
    /* border-top: 1px solid #E0E0E0; */
    border-bottom: 1px solid #E0E0E0;
  }

  article.content-matriz .table-mobile div.body-table div.materia {
    font-weight: 500;
    display: flex;
    align-items: center;
  }

  article.content-matriz .table-mobile div.body-table div.aulas {
    text-align: center;
    font-weight: 600;
    color: #F16F31;
  }

  article.content-matriz .table-mobile div.footer-table div.materia {
    font-weight: 700;
    color: #F16F31;
    background-color: #E8E8E8;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    padding: 5px 10px;
  }

  article.content-matriz .table-mobile div.footer-table div.aulas {
    font-weight: 700;
    color: #FFF;
    border-top-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border: 1px solid #F16F31;
    background-color: #F16F31;
    padding: 5px 10px;
    text-align: center;
  }

  article.agendamento {
    background-color: #F16F31;
    background-size: cover;
    padding: 30px 0;
  }

  article.agendamento h2.display-5 {
    color: #FFF;
    font-size: 2.5rem;
    font-weight: 700;
    line-height: 52px;
    letter-spacing: 0;

    text-transform: uppercase;
  }

  article.agendamento p.lead {
    color: #FFF;
    font-size: 1.25rem;
    font-weight: 300;
    line-height: 26px;
    letter-spacing: 0;
  }

  article.agendamento .btn-admissao {
    min-width: 64px;
    height: 36px;

    display: inline-block;

    margin: 0px 0;
    padding-left: 16px;
    padding-right: 16px;

    white-space: nowrap;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;

    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0;

    text-transform: uppercase;
    color: #FFF;

    background-image: none;
    background: linear-gradient(90deg, #FFF 0%, #FFF 50%, transparent 50%, transparent 100%);
    background-size: 200% 100%;
    background-position: right;

    transition: background color 0.5s ease;

    border-top-left-radius: 16px;
    border-bottom-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 16px;

    border: 1px solid #FFF;
  }

  article.agendamento .btn-admissao:hover {
    background-position: left;
    transition: all 0.5s ease;
    color: #F16F31;
  }

  article.destaques {
    padding: 60px 16px;
  }

  article.destaques .container-destaque {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-left: 0;
  }

  article.destaques .container-destaque .destaque-header h2.display-5 {
    font-size: 2.5rem;
    font-weight: 700;
    line-height: 52px;
    letter-spacing: 0;
    text-transform: uppercase;
    text-align: center;
  }

  article.destaques .container-destaque .destaque-box {
    height: 100%;
    padding: 60px 30px;
    text-align: center;
    transition: all .3s ease;

    border-top-left-radius: 32px;
    border-bottom-right-radius: 32px;
    border-bottom-left-radius: 5px;
    border-top-right-radius: 5px;

    border: 1px solid rgba(1, 41, 112, 0.15);
    box-shadow: 0px 0px 8px rgba(1, 41, 112, 0.1);
  }

  article.destaques .container-destaque .destaque-box i.icon {
    font-size: 36px;
    padding: 40px 20px;
    border-radius: 4px;
    position: relative;
    margin-bottom: 25px;
    display: inline-block;
    line-height: 0;
    transition: 0.3s;
  }

  article.destaques .container-destaque .destaque-box h3 {
    margin: 0 0 8px;
    font-size: 1.75rem;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: 0;
    color: #333;
  }

  article.destaques .container-destaque .destaque-box p {
    margin: 0 0 1rem;
  }

  article.destaques .container-destaque .destaque-box .box-orange {
    background-color: rgba(241, 111, 49, 0.18);
    color: #f16f31;
  }

  article.destaques .container-destaque .destaque-box .box-roxo-b {
    background-color: rgba(59, 25, 83, 0.18);
  }

  article.destaques .container-destaque .destaque-box .box-coral {
    background-color: rgba(240, 75, 93, 0.18);
    color: #f04b5d;
  }

  article.destaques .orange {
    border-bottom: 3px solid #F16F31;
  }

  article.destaques .orange:hover {
    background: #F16F31;
    transition: all .3s ease;
  }

  article.destaques .orange:hover i.box-orange {
    background: #FFF;
    transition: all .3s ease;
  }

  article.destaques .orange:hover h3,
  article.destaques .orange:hover p {
    color: #FFF;
    transition: all .3s ease;
  }

  article.destaques .roxo-b {
    border-bottom: 3px solid #623F99;
  }

  article.destaques .roxo-b:hover {
    background: #623F99;
    transition: all .3s ease;
  }

  article.destaques .roxo-b:hover i.box-roxo-b {
    background: #FFF;
    transition: all .3s ease;
  }

  article.destaques .roxo-b:hover h3,
  article.destaques .roxo-b:hover p {
    color: #FFF;
    transition: all .3s ease;
  }

  article.destaques .coral {
    border-bottom: 3px solid #F04B5D;
  }

  article.destaques .coral:hover {
    background: #F04B5D;
    transition: all .3s ease;
  }

  article.destaques .coral:hover i.box-coral {
    background: #FFF;
    transition: all .3s ease;
  }

  article.destaques .coral:hover h3,
  article.destaques .coral:hover p {
    color: #FFF;
    transition: all .3s ease;
  }
}
</style>